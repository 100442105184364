import React from "react";
import PropTypes from "prop-types";
import CaseHistoryTableRow from "./CaseHistoryTableRow";
import { useState } from "react";
import {
  caseHistorySortByOptions,
  sortOrderOptions,
} from "./CaseSortingOptions";
import { updateCaseHistoryTable } from "../Store/mutations";
import { connect } from "react-redux";
import CaseListSorting from "./CaseListSorting";
import CaseSortOrderIcon from "./CaseSortOrderIcon";

const CaseHistoryTable = ({ caseHistory, pageNumber, pageSize }) => {
  var sortedCaseHistory = [...caseHistory];

  const [sortBy, setSortBy] = useState(caseHistorySortByOptions.dateConfirmed);
  const [sortOrder, setSortOrder] = useState(sortOrderOptions.descending);

  const handleUpdateHistorySort = () => {
    sortedCaseHistory = CaseListSorting(caseHistory, sortBy, sortOrder);
  };

  handleUpdateHistorySort();

  const alternateSortOrder = () => {
    if (sortOrder === sortOrderOptions.ascending) {
      setSortOrder(sortOrderOptions.descending);
    } else setSortOrder(sortOrderOptions.ascending);
  };

  const handleSortChange = (sortByColumn) => {
    if (sortByColumn === sortBy) {
      alternateSortOrder();
    } else {
      setSortBy(sortByColumn);
    }
    handleUpdateHistorySort();
  };

  if (pageNumber.length === 0) {
    sortedCaseHistory = [...sortedCaseHistory].slice(0, 1 * pageSize);
  } else
    sortedCaseHistory = [...sortedCaseHistory].slice(
      (Math.max(pageNumber, 1) - 1) * pageSize,
      pageNumber * pageSize
    );

  return (
    <CaseHistoryTablePure
      handleSortChange={handleSortChange}
      sortedCaseHistory={sortedCaseHistory}
      sortBy={sortBy}
      sortOrder={sortOrder}
    />
  );
};

CaseHistoryTable.propTypes = { caseHistory: PropTypes.array.isRequired };

const mapStateToProps = (state) => ({
  caseHistory: state.caseHistory,
  caseHistoryFilter: state.pageControls.caseHistory.pageFilter,
  pageNumber: state.pageControls.caseHistory.pageNumber,
  pageSize: state.pageControls.caseHistory.pageSize,
});

const mapDispatchToProps = (dispatch) => ({
  updateCaseHistoryTable: () => dispatch(updateCaseHistoryTable()),
});

const ConnectedCaseHistoryTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseHistoryTable);
export default ConnectedCaseHistoryTable;

export const CaseHistoryTablePure = ({
  sortedCaseHistory,
  sortBy,
  sortOrder,
  handleSortChange,
}) => {
  return (
    <div id="map-container">
      <table>
        <thead>
          <tr>
            <th
              onClick={() =>
                handleSortChange(caseHistorySortByOptions.caseNumber)
              }
            >
              Case Number
              <CaseSortOrderIcon
                headerName={caseHistorySortByOptions.caseNumber}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>

            <th
              onClick={() =>
                handleSortChange(caseHistorySortByOptions.dateSubmitted)
              }
            >
              Date Published
              <CaseSortOrderIcon
                headerName={caseHistorySortByOptions.dateSubmitted}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th
              onClick={() =>
                handleSortChange(caseHistorySortByOptions.dateConfirmed)
              }
            >
              Date Confirmed
              <CaseSortOrderIcon
                headerName={caseHistorySortByOptions.dateConfirmed}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th>Acknowledge Code</th>
          </tr>
        </thead>

        <tbody>
          {sortedCaseHistory.map((caseData, index) => {
            return <CaseHistoryTableRow caseData={caseData} key={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};
