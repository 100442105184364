import useCasePageControls from "./useCasePageControls";
import { connect } from "react-redux";
import { useEffect } from "react";

const CaseHistoryPagination = ({
  pageNumber,
  pageSize,
  filter,
  caseHistoryCount,
}) => {
  const { handleCasePageChange, handleCasePageSizeChange } =
    useCasePageControls();

  useEffect(() => {
    if (caseHistoryCount <= 0) {
      return;
    }
    if (pageNumber > caseHistoryCount / pageSize) {
      handleCasePageChange(
        "caseHistory",
        Math.ceil(caseHistoryCount / pageSize)
      );
    }
    if (pageNumber === 0) {
      handleCasePageChange("caseHistory", 1);
    }
  }, [pageSize, pageNumber, filter, caseHistoryCount, handleCasePageChange]);

  const getMaxPage = () => {
    if (caseHistoryCount === 0) {
      return 1;
    } else return caseHistoryCount / pageSize;
  };

  return (
    <CaseHistoryPaginationPure
      pageNumber={pageNumber}
      pageSize={pageSize}
      caseHistoryCount={caseHistoryCount}
      handleCasePageChange={handleCasePageChange}
      handleCasePageSizeChange={handleCasePageSizeChange}
      getMaxPage={getMaxPage}
    />
  );
};

const mapStateToProps = (state) => ({
  pageNumber: state.pageControls.caseHistory.pageNumber,
  pageSize: state.pageControls.caseHistory.pageSize,
  filter: state.pageControls.caseHistory.pageFilter,
  caseHistoryCount: state.caseHistory.length,
});

const ConnectedCaseHistoryPagination = connect(mapStateToProps)(
  CaseHistoryPagination
);
export default ConnectedCaseHistoryPagination;

export const CaseHistoryPaginationPure = ({
  pageNumber,
  pageSize,
  caseHistoryCount,
  handleCasePageChange,
  handleCasePageSizeChange,
  getMaxPage,
}) => {
  return (
    <div className="flex-right paginationControl">
      <div className="flex flex-middle flex-auto flex-margin-small pagination">
        <input
          name="pageNumberInput"
          type="number"
          value={pageNumber}
          min="1"
          step="1"
          max={getMaxPage()}
          onChange={(e) => {
            handleCasePageChange("caseHistory", e.target.value);
          }}
          className="paginationElement"
        />
        <span className="fancy paginationElement">of&nbsp;&nbsp;</span>
        <span className="paginationElement">
          {Math.ceil(
            caseHistoryCount < pageSize ? 1 : caseHistoryCount / pageSize
          )}
        </span>
      </div>
      <div className="pageChangeArrows">
        <i
          aria-label="pageDown"
          disabled={pageNumber <= "1"}
          className={`icon icon-chevron-left tip-top  ${
            pageNumber <= "1" ? "disabled" : ""
          }`}
          onClick={() => handleCasePageChange("caseHistory", pageNumber - 1)}
        />
        <i
          aria-label="pageUp"
          disabled={pageNumber >= caseHistoryCount / pageSize}
          className={`icon icon-chevron-right tip-top ${
            pageNumber >= caseHistoryCount / pageSize ? "disabled" : ""
          }`}
          onClick={() => handleCasePageChange("caseHistory", pageNumber + 1)}
        />
      </div>
      <select
        onChange={(e) =>
          handleCasePageSizeChange("caseHistory", e.target.value)
        }
      >
        <option value="25">25 Per Page</option>
        <option value="50">50 Per Page</option>
        <option value="100">100 Per Page</option>
      </select>
    </div>
  );
};
