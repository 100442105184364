import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const SearchForm = ({ id, children, className, title }) => (
  <div className={className}>
    <h3 className="block">{title || _.startCase(id)}</h3>
    <form id={id} className="sidebar-form">
      {children}
    </form>
  </div>
);

SearchForm.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default SearchForm;
