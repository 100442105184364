import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IncidentRow from "./IncidentRow";
import classnames from "classnames";
import "./Incidents.css";

export const Incidents = ({ full, incidentNumber, incidents }) => (
  <IncidentsPure
    full={full}
    incidentNumber={incidentNumber}
    incidents={incidents}
  />
);

Incidents.propTypes = {
  full: PropTypes.bool.isRequired,
  incidentNumber: PropTypes.string.isRequired,
  incidents: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  full: !state.incidentResponses[state.incidentResponseId],
  incidentNumber: state.incidentNumber,
  incidents: state.incidents,
});

const ConnectedIncidents = connect(mapStateToProps)(Incidents);

export default ConnectedIncidents;

export const IncidentsPure = ({ full, incidentNumber, incidents }) => (
  <div className={classnames("blockView", { full })}>
    {incidents
      .filter(
        (incident) =>
          !incidentNumber || incidentNumber === incident.incidentNumber
      )
      .map((incident, index) => (
        <IncidentRow
          incidentNumber={incident.incidentNumber}
          rmsNumber={incident.rmsNumber}
          natureCode={incident.natureCode}
          key={index}
        />
      ))}
  </div>
);
