import moment from "moment";

const CaseHistoryTableRow = ({ caseData }) => {
  const {
    caseNumber,

    dateSubmitted,
    dateConfirmed,
    acknowledgeCode,
  } = caseData;

  return (
    <tr>
      <td>{caseNumber}</td>
      <td>{moment(dateSubmitted).format("YYYY-MM-DD HH:mm:ss")}</td>
      <td>
        {dateConfirmed && moment(dateConfirmed).format("YYYY-MM-DD HH:mm:ss")}
      </td>
      <td>{acknowledgeCode}</td>
    </tr>
  );
};
export default CaseHistoryTableRow;
