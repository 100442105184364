import React from "react";
import PropTypes from "prop-types";
import SearchHeader from "./SearchHeader";
import SearchResults from "./SearchResults";
import SearchPanel from "./SearchPanel";
import moment from "moment";
import { connect } from "react-redux";
import {
  REQUEST_INCIDENT_SEARCH,
  updateIncidentNumberCriteria,
} from "../Store/mutations";
import "./Incidents.css";

class Search extends React.Component {
  componentDidMount() {
    this.props.requestIncidentSearch();
  }

  render() {
    return (
      <div id="main-container">
        <SearchHeader />
        <SearchPanel />
        <SearchResults />
      </div>
    );
  }
}

Search.propTypes = { requestIncidentSearch: PropTypes.func.isRequired };

const mapDispatchToProps = (dispatch) => ({
  requestIncidentSearch: () => {
    var requestIncidentSearch = { type: REQUEST_INCIDENT_SEARCH };
    var incidentNumber = localStorage.getItem("incidentNumber");
    localStorage.removeItem("incidentNumber");
    if (incidentNumber) {
      dispatch(updateIncidentNumberCriteria(incidentNumber));
    } else {
      requestIncidentSearch.name = "incidentDate";
      requestIncidentSearch.value = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }
    dispatch(requestIncidentSearch);
  },
});

const ConnectedSearch = connect(null, mapDispatchToProps)(Search);

export default ConnectedSearch;
