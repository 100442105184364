export const sortByOptions = {
  cadId: "dispatchOfficerId",
  firstName: "firstName",
  lastName: "lastName",
  middleName: "middleName",
  badgeNumber: "badge",
  title: "title",
};

export const sortOrderOptions = {
  ascending: "ascending",
  descending: "descending",
};
