import gotoElUrl from "../gotoElUrl";
import moment from "moment";

const CaseTableRow = ({ caseData, openPublishModal }) => {
  const {
    id,
    caseNumber,
    createdByName,
    createdOn,
    eventCount,
    linkCount,
    fileCount,
  } = caseData;

  let caseDetailsUri = `/#caseDetails/${id}`;
  return (
    <tr>
      <td>{caseNumber}</td>
      <td>{createdByName}</td>
      <td>{moment(createdOn).format("YYYY-MM-DD HH:mm:ss")}</td>
      <td>{eventCount}</td>
      <td>{linkCount}</td>
      <td>{fileCount}</td>
      <td>
        <button onClick={() => openPublishModal(caseNumber)}>PUBLISH</button>
      </td>
      <td>
        <a
          href={gotoElUrl(caseDetailsUri)}
          target="_blank"
          rel="noreferrer"
          className="btn btn-primary"
        >
          <button>Details</button>
        </a>
      </td>
    </tr>
  );
};

export default CaseTableRow;
