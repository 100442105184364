export const caseSortByOptions = {
  caseNumber: "caseNumber",
  createdByName: "createdByName",
  createdOn: "createdOn",
};

export const caseHistorySortByOptions = {
  caseNumber: "caseNumber",
  techShareReqId: "techShareReqId",
  dateSubmitted: "dateSubmitted",
  dateConfirmed: "dateConfirmed",
};

export const sortOrderOptions = {
  ascending: "ascending",
  descending: "descending",
};
