import React from "react";

import "../Exports.css";
import { connect } from "react-redux";
import ExportCasesTable from "./Cases/ExportCasesTable";
import ExportEventsTable from "./Events/ExportEventsTable";

const ExportSearchResults = ({ selectedType }) => {
  return (
    <div id="content-outer-wrapper" className="flex flex-expand">
      <div className="flex" style={{ width: "100%" }}>
        <div id="exports-content-wrapper">
          {selectedType === "case" && <ExportCasesTable />}
          {selectedType === "event" && <ExportEventsTable />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedType: state.genericExportOptions.selectedType,
});

export const ConnectedExportSearchResults =
  connect(mapStateToProps)(ExportSearchResults);

export default ConnectedExportSearchResults;
