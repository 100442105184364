import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SearchForm from "../Common/SearchForm";
import InputCheckbox from "../Common/InputCheckbox";
import IncidentEventTimeSearchSlider from "./IncidentEventTimeSearchSlider";
import { REQUEST_INCIDENT_EVENT_SEARCH } from "../Store/mutations";
import "./Incidents.css";

export const IncidentEventsSearchForm = ({ officer, officerIds }) => (
  <SearchForm id="eventFilter" className="top-gray-border">
    <InputCheckbox
      action={REQUEST_INCIDENT_EVENT_SEARCH}
      collection="incidentEventSearchFilter"
      disabled={!officerIds}
      id="officer"
      tooltip="Search includes Officer information"
    />
    <InputCheckbox
      action={REQUEST_INCIDENT_EVENT_SEARCH}
      className="scoochOverALittle"
      collection="incidentEventSearchFilter"
      disabled={!officer}
      id="includeUnknown"
      tooltip="Include Unknown Officers in search"
    />
    <InputCheckbox
      action={REQUEST_INCIDENT_EVENT_SEARCH}
      collection="incidentEventSearchFilter"
      id="existingMatches"
      tooltip="Include Existing Event Matches in search"
    />
    <InputCheckbox
      action={REQUEST_INCIDENT_EVENT_SEARCH}
      collection="incidentEventSearchFilter"
      id="includeLinked"
      tooltip="Include Events Linked to Matches"
    />
    <IncidentEventTimeSearchSlider />
  </SearchForm>
);

IncidentEventsSearchForm.propTypes = {
  officer: PropTypes.bool.isRequired,
  officerIds: PropTypes.string,
};

const mapStateToProps = (state) => {
  const incidentResponse = state.incidentResponses[state.incidentResponseId];
  return {
    officer: state.incidentEventSearchFilter.officer,
    officerIds: incidentResponse.officerIds,
  };
};

const ConnectIncidentEventsSearchForm = connect(mapStateToProps)(
  IncidentEventsSearchForm
);

export default ConnectIncidentEventsSearchForm;
