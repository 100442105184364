import React from "react";
import PropTypes from "prop-types";
import CaseTableRow from "./CaseTableRow";
import { connect } from "react-redux";
import CaseListSorting from "./CaseListSorting";
import CaseSortOrderIcon from "./CaseSortOrderIcon";
import { caseSortByOptions, sortOrderOptions } from "./CaseSortingOptions";
import CasePublishInputForm from "./CasePublishInputForm";
import { useState } from "react";
import "./Cases.css";

export const CaseTable = ({ cases, pageNumber, pageSize }) => {
  var sortedCases = [...cases];

  const [sortBy, setSortBy] = useState(caseSortByOptions.caseNumber);
  const [sortOrder, setSortOrder] = useState(sortOrderOptions.ascending);
  const [showModal, setShowModal] = useState(false);
  const [selectedCaseNumber, setSelectedCaseNumber] = useState(null);

  const openPublishModalwithCaseNumber = (value) => {
    setSelectedCaseNumber(value);
    setShowModal(true);
  };

  const handleUpdateCaseSort = () => {
    sortedCases = CaseListSorting(cases, sortBy, sortOrder);
  };

  handleUpdateCaseSort();

  const alternateSortOrder = () => {
    if (sortOrder === sortOrderOptions.ascending) {
      setSortOrder(sortOrderOptions.descending);
    } else setSortOrder(sortOrderOptions.ascending);
  };

  const handleSortChange = (sortByColumn) => {
    if (sortByColumn === sortBy) {
      alternateSortOrder();
    } else {
      setSortBy(sortByColumn);
    }
    handleUpdateCaseSort();
  };

  if (pageNumber.length === 0) {
    cases = [...cases].slice(0, 1 * pageSize);
  } else
    cases = [...cases].slice(
      (Math.max(pageNumber, 1) - 1) * pageSize,
      pageNumber * pageSize
    );

  return (
    <CaseTablePure
      sortedCases={sortedCases}
      selectedCaseNumber={selectedCaseNumber}
      showModal={showModal}
      sortBy={sortBy}
      sortOrder={sortOrder}
      handleSortChange={handleSortChange}
      setShowModal={setShowModal}
      openPublishModalwithCaseNumber={openPublishModalwithCaseNumber}
    />
  );
};

CaseTable.propTypes = { cases: PropTypes.array.isRequired };

const mapStateToProps = (state) => ({
  pageNumber: state.pageControls.case.pageNumber,
  pageSize: state.pageControls.case.pageSize,
});

const ConnectedCaseTable = connect(mapStateToProps)(CaseTable);
export default ConnectedCaseTable;

export const CaseTablePure = ({
  sortedCases,
  selectedCaseNumber,
  showModal,
  sortBy,
  sortOrder,
  handleSortChange,
  setShowModal,
  openPublishModalwithCaseNumber,
}) => {
  return (
    <div id="map-cases-container">
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSortChange(caseSortByOptions.caseNumber)}>
              Case Number
              <CaseSortOrderIcon
                headerName={caseSortByOptions.caseNumber}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th
              onClick={() => handleSortChange(caseSortByOptions.createdByName)}
            >
              Created By
              <CaseSortOrderIcon
                headerName={caseSortByOptions.createdByName}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th onClick={() => handleSortChange(caseSortByOptions.createdOn)}>
              Created On
              <CaseSortOrderIcon
                headerName={caseSortByOptions.createdOn}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th>Events</th>
            <th>Links</th>
            <th>Files</th>
            <th>Publish</th>
            <th>View Case Details</th>
          </tr>
        </thead>

        <tbody>
          {sortedCases.map((caseData, index) => {
            return (
              <CaseTableRow
                caseData={caseData}
                key={index}
                openPublishModal={openPublishModalwithCaseNumber}
              />
            );
          })}
        </tbody>
      </table>
      {showModal &&
        typeof selectedCaseNumber === "string" &&
        selectedCaseNumber.length > 0 && (
          <CasePublishInputForm
            setShowModal={setShowModal}
            selectedCaseNumber={selectedCaseNumber}
          />
        )}
    </div>
  );
};
