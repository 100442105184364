import React from "react";

import "../Exports.css";
// import { connect } from "react-redux";

import ExportHistoryTable from "./ExportHistoryTable";

const ExportHistorySearchResults = () => {
  return (
    <div id="content-outer-wrapper" className="flex flex-expand">
      <div className="flex" style={{ width: "100%" }}>
        <div id="exports-content-wrapper">
          <ExportHistoryTable />
        </div>
      </div>
    </div>
  );
};

export default ExportHistorySearchResults;
//const mapStateToProps = (state) => ({});

// export const ConnectedExportHistorySearchResults = connect(mapStateToProps)(
//   ExportHistorySearchResults
// );

//export default ConnectedExportHistorySearchResults;
