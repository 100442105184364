import React from "react";
import CaseSearchForm from "./CaseSearchForm";

const CaseSearchPanel = () => (
  <div id="sidebar" className="sidebar-search full-height alt-style">
    <div className="sidebar-search-container">
      <div className="sidebar-scroll-fade-top"></div>
      <div className="sidebar-search-wrapper">
        <div className="sidebar-search-content">
          <CaseSearchForm />
        </div>
      </div>
    </div>
  </div>
);

export default CaseSearchPanel;
