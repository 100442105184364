import OfficerPagination from "./OfficerPagination";
import React from "react";

const OfficerHeader = ({ loadingMessage, numberOfOfficers }) => {
  return (
    <div id="content-header" className="officerHeader">
      <div className="OfficerHeaderLeft">
        <h1>
          {loadingMessage ? (
            <span className="secondary">
              <i className="icon-spinner-alt icon-spin" />
              {loadingMessage}
            </span>
          ) : (
            <>{numberOfOfficers} Officers Found</>
          )}
        </h1>
      </div>
      <div className="OfficerHeaderRight">
        <OfficerPagination numberOfOfficers={numberOfOfficers} />
      </div>
    </div>
  );
};

export default OfficerHeader;
