const ErrorHeader = () => {
  return (
    <div id="content-header" className="flex">
      <h1 className="flex-10">
        <i className="icon icon-error" />
        <span>Error</span>
      </h1>
    </div>
  );
};

export default ErrorHeader;
