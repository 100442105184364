import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestIncidentResponseSelection } from "../Store/mutations";
import classnames from "classnames";
import _ from "lodash";
import moment from "moment";
import "./Incidents.css";

export const IncidentResponseRow = ({
  incident,
  incidentResponse,
  incidentResponseSelected,
  selectIncidentResponse,
}) => (
  <tr
    className={classnames({ responseSelected: incidentResponseSelected })}
    onClick={selectIncidentResponse}
  >
    <td className={classnames({ "padding-shrink": incidentResponseSelected })}>
      {incident.incidentNumber}
    </td>
    <td>
      {moment
        .utc(incidentResponse.dispatchStartTime)
        .local()
        .format("YYYY-MM-DD ")}
      <span className="standOutTime">
        {moment.utc(incidentResponse.dispatchStartTime).local().format("HH:mm")}
      </span>
    </td>
    <td>
      {moment
        .utc(incidentResponse.dispatchClearTime)
        .local()
        .format("YYYY-MM-DD ")}
      <span className="standOutTime">
        {moment.utc(incidentResponse.dispatchClearTime).local().format("HH:mm")}
      </span>
    </td>
    <td>
      {incidentResponse.personnel &&
        incidentResponse.personnel
          .map((person) => _.startCase(_.lowerCase(person.lastName)))
          .join(", ")}
    </td>
    <td>{incidentResponse.vehicleId}</td>
  </tr>
);

IncidentResponseRow.propTypes = {
  incident: PropTypes.shape({
    incidentNumber: PropTypes.string.isRequired,
    natureCode: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    street3: PropTypes.string,
    street4: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  incidentResponse: PropTypes.shape({
    personnel: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      })
    ),
    vehicleId: PropTypes.string,
    dispatchStartTime: PropTypes.string.isRequired,
    dispatchClearTime: PropTypes.string.isRequired,
  }).isRequired,
  incidentResponseSelected: PropTypes.bool.isRequired,
  selectIncidentResponse: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const incidentResponse = state.incidentResponses[ownProps.id];
  const incident = state.incidents.filter(
    (incident) => incident.incidentNumber === state.incidentNumber
  )[0];
  return {
    incident,
    incidentResponse,
    incidentResponseSelected: state.incidentResponseId === ownProps.id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectIncidentResponse: () =>
    dispatch(requestIncidentResponseSelection(ownProps.id)),
});

const ConnectedIncidentResponseRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentResponseRow);

export default ConnectedIncidentResponseRow;
