import storeURLQuery from "./StoreURLQuery";
import { get } from "./ApiClient";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  agencyConfigError,
  integrationDisabled,
  integrationEnabled,
  setTaggingOptions,
  enabletechSharePublish,
  setGenericExportOptions,
  setUserName,
} from "./Store/mutations";

const useStartUp = () => {
  storeURLQuery();
  const dispatch = useDispatch();
  const FetchAgencyConfig = async function () {
    get("AgencyConfig")
      .then((response) => {
        const agencyConfig = response.data;
        localStorage.setItem("AgencyConfig", JSON.stringify(agencyConfig));

        const integrationManagerOptions =
          agencyConfig.integrationManagerOptions;

        var userName = jwtDecode(localStorage.getItem("token")).name;
        if (typeof userName === "string" && userName.length !== 0) {
          dispatch(setUserName(userName));
        }
        if (integrationManagerOptions.genericExportOptions !== null) {
          dispatch(
            setGenericExportOptions(
              integrationManagerOptions.genericExportOptions
            )
          );
        }

        if (integrationManagerOptions.techShareUploadEnabled) {
          dispatch(enabletechSharePublish());
        }

        const delimiter =
          agencyConfig.taggingOptions.secondaryTagMultiTagDelimiter === "\x00"
            ? ","
            : agencyConfig.taggingOptions.secondaryTagMultiTagDelimiter;

        const {
          disableMultiTag,
          incidentNumberTagName,
          rmsNumberTagName,
          natureCodeTagName,
        } = agencyConfig.taggingOptions;

        dispatch(
          setTaggingOptions(
            delimiter,
            disableMultiTag,
            incidentNumberTagName,
            rmsNumberTagName,
            natureCodeTagName
          )
        );
      })
      .catch((error) => {
        dispatch(agencyConfigError(error));
      });
  };

  if (localStorage.getItem("token") !== null) {
    dispatch(integrationEnabled());
    FetchAgencyConfig();
  } else dispatch(integrationDisabled());
};

export default useStartUp;
