import React from "react";
import Incidents from "./Incidents";
import "./Incidents.css";

export const SearchResults = () => (
  <div id="content-outer-wrapper" className="flex flex-expand">
    <div className="flex" style={{ width: "100%" }}>
      <div id="content-wrapper">
        <Incidents />
      </div>
    </div>
  </div>
);

export default SearchResults;
