import { sortOrderOptions } from "./ExportsSortingOptions";

const exportListSorting = (exports, sortBy, sortOrder) => {
  var sortedExports = [...exports];

  switch (sortOrder) {
    case sortOrderOptions.descending:
      sortedExports = [...sortedExports].sort((b, a) => {
        if (typeof a[sortBy] !== "string" || a[sortBy] === "") return -1;
        if (typeof b[sortBy] !== "string" || b[sortBy] === "") return 1;
        return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
      });
      break;
    case sortOrderOptions.ascending:
    default:
      sortedExports = [...sortedExports].sort((a, b) => {
        if (typeof a[sortBy] !== "string" || a[sortBy] === "") return 1;
        if (typeof b[sortBy] !== "string" || b[sortBy] === "") return -1;
        return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
      });
  }

  return sortedExports;
};

export default exportListSorting;
