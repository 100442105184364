import React from "react";
import { updatePageFilter } from "../../Store/mutations";
import { connect } from "react-redux";
import "../Exports.css";

export const ExportHistoryFilter = ({ filter }) => {
  return (
    <div className="filterInput">
      <input
        type="string"
        className="form-control col-sm-4 ExportInput"
        id="filter"
        placeholder="Filter..."
        aria-label="Export Filter"
        aria-describedby="basic-addon2"
        onChange={(e) => filter("exportHistory", e.target.value)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  filter: (pageName, filter) => dispatch(updatePageFilter(pageName, filter)),
});

const ConnectedExportHistoryFilter = connect(
  null,
  mapDispatchToProps
)(ExportHistoryFilter);

export default ConnectedExportHistoryFilter;

export const applyExportHistoryFilter = (exportHistory, filter) => {
  var filteredHistory =
    exportHistory[0] === "loading"
      ? []
      : [
          ...exportHistory.filter((exportitem) =>
            Object.values(exportitem).some(
              (value) =>
                value !== null &&
                value.toString().toLowerCase().includes(filter.toLowerCase())
            )
          ),
        ];
  return filteredHistory;
};
