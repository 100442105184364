import React, { useEffect } from "react";
import CaseSearchHeader from "./CaseSearchHeader";
import CaseSearchPanel from "./CaseSearchPanel";
import CaseSearchResults from "./CaseSearchResults";
import moment from "moment";
import { connect } from "react-redux";
import { REQUEST_CASE_SEARCH } from "../Store/mutations";

import "./Cases.css";

export const CasesPage = ({ techSharePublishEnabled, requestCaseSearch }) => {
  if (!techSharePublishEnabled) {
    window.location.pathname = "/";
  }
  useEffect(() => {
    requestCaseSearch();
  }, [requestCaseSearch]);

  return (
    <div id="cases-content-outer-wrapper">
      <div id="content-header-container" className="indented">
        <CaseSearchHeader pageName="case" />
      </div>
      <CaseSearchPanel />
      <CaseSearchResults />
    </div>
  );
};

const mapStateToProps = (state) => ({
  techSharePublishEnabled: state.techSharePublishEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  requestCaseSearch: () => {
    var requestCaseSearch = { type: REQUEST_CASE_SEARCH };
    var caseNumber = localStorage.getItem("caseNumber");
    localStorage.removeItem("caseNumber");
    if (caseNumber) {
      requestCaseSearch.name = "caseNumber";
      requestCaseSearch.value = caseNumber;
    } else {
      requestCaseSearch.name = "caseCreationDate";
      requestCaseSearch.value = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }
    dispatch(requestCaseSearch);
  },
});

const ConnectedCasesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CasesPage);

export default ConnectedCasesPage;
