import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IncidentResponses from "./IncidentResponses";
import {
  requestIncidentSelection,
  requestIncidentDeselection,
} from "../Store/mutations";
import classnames from "classnames";
import ActionMenu from "./ActionMenu";
import IncidentEvents from "./IncidentEvents";
import "./Incidents.css";
import _ from "lodash";

export const IncidentRow = ({
  incident,
  incidentSelected,
  showIncidentEvents,
  showIncidentResponses,
  onSelect,
  onDeselect,
}) => (
  <IncidentRowPure
    incident={incident}
    incidentSelected={incidentSelected}
    showIncidentEvents={showIncidentEvents}
    showIncidentResponses={showIncidentResponses}
    onSelect={onSelect}
    onDeselect={onDeselect}
  />
);

IncidentRow.propTypes = {
  incident: PropTypes.shape({
    incidentNumber: PropTypes.string.isRequired,
    natureCode: PropTypes.string,
    rmsNumber: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    street3: PropTypes.string,
    street4: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  incidentSelected: PropTypes.bool.isRequired,
  showIncidentEvents: PropTypes.bool.isRequired,
  showIncidentResponses: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  incident: state.incidents.find(
    (incident) => incident.incidentNumber === ownProps.incidentNumber
  ),
  incidentSelected: state.incidentNumber === ownProps.incidentNumber,
  showIncidentEvents: !!state.incidentResponses[state.incidentResponseId],
  showIncidentResponses: !!state.incidentResponses.length,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect: () =>
    dispatch(
      requestIncidentSelection(
        ownProps.incidentNumber,
        ownProps.rmsNumber,
        ownProps.natureCode
      )
    ),
  onDeselect: () => dispatch(requestIncidentDeselection()),
});

const ConnectedIncidentRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentRow);

export default ConnectedIncidentRow;

export const IncidentRowPure = ({
  incident,
  incidentSelected,
  showIncidentEvents,
  showIncidentResponses,
  onSelect,
  onDeselect,
}) => (
  <>
    <section
      className={classnames("item case-item flex", { incidentSelected })}
      onClick={incidentSelected ? onDeselect : onSelect}
    >
      <div
        className={classnames("item-main flex incident-row", {
          incidentSelected,
        })}
      >
        <div className="block-middle">
          <div className="flex">
            <div className="flex-2">
              <label>Responses</label>
              <div>
                <i
                  className={classnames({
                    "icon-chevron-down": incidentSelected,
                    "icon-chevron-right": !incidentSelected,
                  })}
                />
              </div>
            </div>
            <div className="flex-3">
              <label>
                {
                  JSON.parse(localStorage.getItem("AgencyConfig"))
                    .taggingOptions.incidentNumberTagName
                }
              </label>
              <div>{incident.incidentNumber}</div>
            </div>
            <div className="flex-7">
              <label>Address</label>
              <div>
                {_.filter(
                  [
                    incident.street1,
                    incident.street2,
                    incident.street3,
                    incident.street4,
                    incident.city && incident.city + ",",
                    incident.province,
                    incident.postalCode,
                  ].join(" ")
                )}
              </div>
            </div>
            <div className="flex-3">
              <label>Nature Code</label>
              <div>{incident.natureCode}</div>
            </div>
          </div>
        </div>
        <ActionMenu id={incident.incidentNumber} />
        <b className={classnames("selected-indicator", { incidentSelected })} />
      </div>
    </section>
    <div>
      {incidentSelected && showIncidentResponses && <IncidentResponses />}
      {incidentSelected && showIncidentEvents && <IncidentEvents />}
    </div>
  </>
);
