import React from "react";
import SearchForm from "../../Common/SearchForm";
import { connect } from "react-redux";
import {
  updateExportStatusHistoryQuery,
  updateExportDestinationHistoryQuery,
} from "../../Store/mutations";

export const ExportHistorySearchForm = ({
  configurationIds,
  updateExportDestinationHistoryQuery,
  updateExportStatusHistoryQuery,
  selectedDestination,
  selectedStatus,
}) => {
  return (
    <>
      <SearchForm id="exportControl" title={`Export Control`}>
        <label>Export Destination</label>
        <select
          aria-label="select destination"
          defaultValue={selectedDestination}
          onChange={({ target }) => {
            updateExportDestinationHistoryQuery(target.value);
          }}
        >
          <option disabled value="default">
            Choose destination
          </option>
          <option value="all">All</option>
          {configurationIds.map((value, index) => (
            <option value={value} key={index}>
              {value}
            </option>
          ))}
        </select>
        <label>Export Status</label>
        <select
          aria-label="select status"
          defaultValue={selectedStatus}
          onChange={({ target }) =>
            updateExportStatusHistoryQuery(target.value)
          }
        >
          <option disabled value="default">
            Choose status
          </option>
          <option value="all">All</option>
          <option value="complete">Complete</option>
          <option value="incomplete">Incomplete</option>
        </select>
      </SearchForm>
    </>
  );
};

const mapStateToProps = (state) => ({
  configurationIds: state.genericExportOptions.configurationIds,
  types: state.genericExportOptions.types,
  selectedDestination: state.exportHistoryOptions.selectedDestinationHistory,
  selectedStatus: state.exportHistoryOptions.selectedStatusHistory,
});

const mapDispatchToProps = (dispatch) => ({
  updateExportStatusHistoryQuery: (value) =>
    dispatch(updateExportStatusHistoryQuery(value)),
  updateExportDestinationHistoryQuery: (value) =>
    dispatch(updateExportDestinationHistoryQuery(value)),
});

const ConnectedExportHistorySearchForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportHistorySearchForm);
export default ConnectedExportHistorySearchForm;
