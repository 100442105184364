import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OfficerRow from "./OfficerRow";
import { sortByOptions, sortOrderOptions } from "./OfficerSortingOptions";
import "./Officers.css";
import officerListSorting from "./officerListSorting";
import OfficerSortOrderIcon from "./OfficerSortOrderIcon";

export const OfficerList = ({
  loadingMessage,
  officers,
  pageSize,
  pageNumber,
}) => {
  var sortedOfficers = [...officers];

  const [sortBy, setSortBy] = useState(sortByOptions.lastName);
  const [sortOrder, setSortOrder] = useState(sortOrderOptions.ascending);

  const handleUpdateOfficerSort = () => {
    sortedOfficers = officerListSorting(officers, sortBy, sortOrder);
  };

  handleUpdateOfficerSort();

  if (pageNumber.length === 0) {
    sortedOfficers = [...sortedOfficers].slice(0, 1 * pageSize);
  } else
    sortedOfficers = [...sortedOfficers].slice(
      (Math.max(pageNumber, 1) - 1) * pageSize,
      pageNumber * pageSize
    );

  const alternateSortOrder = () => {
    if (sortOrder === sortOrderOptions.ascending) {
      setSortOrder(sortOrderOptions.descending);
    } else setSortOrder(sortOrderOptions.ascending);
  };

  const handleSortChange = (sortByColumn) => {
    if (sortByColumn === sortBy) {
      alternateSortOrder();
    } else {
      setSortBy(sortByColumn);
    }
    handleUpdateOfficerSort();
  };

  return (
    <OfficerListPure
      loadingMessage={loadingMessage}
      sortBy={sortBy}
      sortOrder={sortOrder}
      sortedOfficers={sortedOfficers}
      handleSortChange={handleSortChange}
    />
  );
};

OfficerList.propTypes = { officers: PropTypes.array.isRequired };

const mapStateToProps = (state) => ({
  loadingMessage: state.loadingMessage,
});

const ConnectedOfficerList = connect(mapStateToProps)(OfficerList);

export default ConnectedOfficerList;

export const OfficerListPure = ({
  loadingMessage,
  sortBy,
  sortOrder,
  sortedOfficers,
  handleSortChange,
}) => {
  return (
    <>
      {!loadingMessage && (
        <div id="map-container">
          <table id="officer-map-table fixed_header">
            <thead className="fixed_header">
              <tr>
                <th className="col-cadId">New CAD ID </th>
                <th className="col-id">
                  <div onClick={() => handleSortChange(sortByOptions.cadId)}>
                    Current CAD ID{" "}
                  </div>
                  <OfficerSortOrderIcon
                    headerName={sortByOptions.cadId}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                </th>
                <th className="col-lastName">
                  <div onClick={() => handleSortChange(sortByOptions.lastName)}>
                    Last Name{" "}
                  </div>
                  <OfficerSortOrderIcon
                    headerName={sortByOptions.lastName}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                </th>
                <th className="col-firstName">
                  <div
                    onClick={() => handleSortChange(sortByOptions.firstName)}
                  >
                    First Name{" "}
                  </div>

                  <OfficerSortOrderIcon
                    headerName={sortByOptions.firstName}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                </th>
                <th className="col-middleName">
                  <div
                    onClick={() => handleSortChange(sortByOptions.middleName)}
                  >
                    Middle Name{" "}
                  </div>
                  <OfficerSortOrderIcon
                    headerName={sortByOptions.middleName}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                </th>
                <th className="col-badge">
                  <div
                    onClick={() => handleSortChange(sortByOptions.badgeNumber)}
                  >
                    Badge Number{" "}
                  </div>
                  <OfficerSortOrderIcon
                    headerName={sortByOptions.badgeNumber}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                </th>
                <th className="col-title">
                  <div onClick={() => handleSortChange(sortByOptions.title)}>
                    Title{" "}
                  </div>

                  <OfficerSortOrderIcon
                    headerName={sortByOptions.title}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedOfficers.length === 0 ? (
                <tr>
                  <td>No officers available to map</td>
                </tr>
              ) : (
                sortedOfficers.map((officer, index) => (
                  <OfficerRow officer={officer} id={officer.id} key={index} />
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
