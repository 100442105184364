import "./Home.css";

const HomeHeader = () => {
  return (
    <div id="content-header-container homeheader">
      <div id="content-header">
        <div className="flex-10">
          <h1>
            <span className="icon-circle"></span>
            <span>Home</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
