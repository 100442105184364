import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { sortOrderOptions } from "./ExportsSortingOptions";

const ExportsSortOrderIcon = ({ headerName, sortBy, sortOrder }) => {
  if (sortBy !== headerName) return;

  return (
    <div className="sortOrderIcon">
      {sortOrder === sortOrderOptions.ascending ? (
        <BsFillCaretUpFill />
      ) : (
        <BsFillCaretDownFill />
      )}
    </div>
  );
};

export default ExportsSortOrderIcon;
