import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestIncidentEventSearch } from "../Store/mutations";
import { DebounceInput } from "react-debounce-input";
import "./Incidents.css";
import moment from "moment";

export const IncidentEventTimeSearchSlider = ({
  incidentResponse,
  startTimeOffsetInMinutes,
  stopTimeOffsetInMinutes,

  requestIncidentEventSearch,
}) => (
  <React.Fragment>
    <div className="flex flex-middle">
      <label className="align-left record-start">Record Start</label>
    </div>
    <div className="right-inner-addon">
      <i className="icon-play" />
      <input
        readOnly
        type="datetime-local"
        value={moment
          .utc(incidentResponse.dispatchStartTime)
          .local()
          .add(startTimeOffsetInMinutes, "minutes")
          .format("YYYY-MM-DDTHH:mm")}
      />
    </div>
    <div className="flex flex-middle">
      <label className="align-left record-stop">Record Stop</label>
    </div>
    <div className="right-inner-addon">
      <i className="icon-stop" />
      <input
        readOnly
        type="datetime-local"
        value={moment
          .utc(incidentResponse.dispatchClearTime)
          .local()
          .add(stopTimeOffsetInMinutes, "minutes")
          .format("YYYY-MM-DDTHH:mm")}
      />
    </div>
    <div id="slider-container">
      <span>
        <DebounceInput
          debounceTimeout={500}
          id="startTimeOffsetInMinutes"
          max={0}
          min={-120}
          onChange={requestIncidentEventSearch}
          step={5}
          type="range"
          list="startTime"
          value={startTimeOffsetInMinutes}
        />
        <div id="event-representation" />
        <DebounceInput
          debounceTimeout={500}
          id="stopTimeOffsetInMinutes"
          max={120}
          min={0}
          onChange={requestIncidentEventSearch}
          step={5}
          type="range"
          value={stopTimeOffsetInMinutes}
        />
      </span>
    </div>
    <datalist id="startTime">
      <option value="0" label="Event Start" />
    </datalist>
  </React.Fragment>
);

IncidentEventTimeSearchSlider.propTypes = {
  incidentResponse: PropTypes.shape({
    dispatchStartTime: PropTypes.string.isRequired,
    dispatchClearTime: PropTypes.string.isRequired,
  }).isRequired,
  startTimeOffsetInMinutes: PropTypes.number.isRequired,
  stopTimeOffsetInMinutes: PropTypes.number.isRequired,
  requestIncidentEventSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const incidentResponse = state.incidentResponses[state.incidentResponseId];
  return {
    incidentResponse,
    startTimeOffsetInMinutes: parseInt(
      state.incidentEventSearchFilter.startTimeOffsetInMinutes
    ),
    stopTimeOffsetInMinutes: parseInt(
      state.incidentEventSearchFilter.stopTimeOffsetInMinutes
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestIncidentEventSearch: (event) =>
    dispatch(requestIncidentEventSearch(event.target.id, event.target.value)),
});

const ConnectIncidentEventTimeSearchSlider = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentEventTimeSearchSlider);

export default ConnectIncidentEventTimeSearchSlider;
