import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestOfficerList } from "../Store/mutations";
import OfficerHeader from "./OfficerHeader";
import OfficerList from "./OfficerList";
import "./Officers.css";

export const Officers = ({
  loadingMessage,
  officers,
  requestOfficerList,
  pageNumber,
  filter,
  pageSize,
}) => {
  useEffect(() => {
    if (officers.length === 0) {
      requestOfficerList();
    }
  }, [officers, requestOfficerList]);

  const officerData = officers.map((officer) => {
    return {
      dispatchOfficerId: officer.dispatchOfficerId,
      id: officer.id,
      lastName: officer.lastName,
      firstName: officer.firstName,
      middleName: officer.middleName,
      badge: officer.badge,
      title: officer.title,
    };
  });

  var filteredOfficers =
    officers[0] === "loading"
      ? []
      : [
          ...officerData.filter((officer) =>
            Object.values(officer).some(
              (value) =>
                value !== null &&
                value.toString().toLowerCase().includes(filter.toLowerCase())
            )
          ),
        ];

  return (
    <OfficersPure
      loadingMessage={loadingMessage}
      filteredOfficers={filteredOfficers}
      pageSize={pageSize}
      pageNumber={pageNumber}
      filter={filter}
    />
  );
};

Officers.propTypes = { requestOfficerList: PropTypes.func.isRequired };

const mapStateToProps = (state) => ({
  loadingMessage: state.loadingMessage,
  officers: state.officers,
  pageNumber: state.pageControls.officer.pageNumber,
  filter: state.pageControls.officer.pageFilter,
  pageSize: state.pageControls.officer.pageSize,
});

const mapDispatchToProps = (dispatch) => ({
  requestOfficerList: () => dispatch(requestOfficerList()),
});

const ConnectedOfficers = connect(
  mapStateToProps,
  mapDispatchToProps
)(Officers);

export default ConnectedOfficers;

export const OfficersPure = ({
  loadingMessage,
  filteredOfficers,
  pageSize,
  pageNumber,
  filter,
}) => {
  return (
    <div className="officer-mapping full-height" id="main-container">
      <div id="officer-content-outer-wrapper">
        <OfficerHeader
          loadingMessage={loadingMessage}
          numberOfOfficers={filteredOfficers.length}
        />

        <OfficerList
          officers={filteredOfficers}
          pageSize={pageSize}
          pageNumber={pageNumber}
          filter={filter}
        />
      </div>
    </div>
  );
};
