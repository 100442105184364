import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IncidentSearchForm from "./IncidentSearchForm";
import IncidentEventSearchForm from "./IncidentEventSearchForm";

export const SearchPanel = ({ showIncidentEventSearchForm }) => (
  <div id="sidebar" className="sidebar-search full-height alt-style">
    <div className="sidebar-search-container">
      <div className="sidebar-scroll-fade-top"></div>
      <div className="sidebar-search-wrapper">
        <div className="sidebar-search-content">
          <IncidentSearchForm />
          {showIncidentEventSearchForm ? <IncidentEventSearchForm /> : ""}
        </div>
      </div>
    </div>
  </div>
);

SearchPanel.propTypes = {
  showIncidentEventSearchForm: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showIncidentEventSearchForm:
    !!state.incidentResponses[state.incidentResponseId],
});

const ConnectedSearchPanel = connect(mapStateToProps)(SearchPanel);

export default ConnectedSearchPanel;
