import { connect } from "react-redux";

const ExportRowExportButton = ({
  selectedType,
  selectedDestination,
  handleClick,
  isDisabled,
}) => {
  const hasValidSelectedType = () => {
    return (
      typeof selectedType === "string" &&
      selectedType.length > 0 &&
      selectedType !== "default"
    );
  };
  const hasValidSelectedDestination = () => {
    return (
      typeof selectedDestination === "string" &&
      selectedDestination.length > 0 &&
      selectedDestination !== "default"
    );
  };
  return (
    <button
      onClick={() => handleClick()}
      disabled={
        !hasValidSelectedDestination() || !hasValidSelectedType() || isDisabled
      }
      className={
        hasValidSelectedDestination() && hasValidSelectedType()
          ? ""
          : "disabled"
      }
    >
      Export
    </button>
  );
};

const mapStateToProps = (state) => ({
  selectedType: state.genericExportOptions.selectedType,
  selectedDestination: state.genericExportOptions.selectedDestination,
});

export const ConnectedExportRowExportButton = connect(mapStateToProps)(
  ExportRowExportButton
);

export default ConnectedExportRowExportButton;
