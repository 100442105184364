import gotoElUrl from "./gotoElUrl";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from "react";
export const AppHeader = ({
  error,
  integrationEnabled,
  techSharePublishEnabled,
  genericExportOptions,
  userName,
}) => {
  const [activeLink, setActiveLink] = useState("");
  var elUrl = gotoElUrl();
  const hideMenu = () => {
    if (Object.keys(error).length > 0) return true;
    return !integrationEnabled;
  };

  return (
    <header id="header" className="AppHeader">
      <div className="header-left">
        <NavLink id="logo" to="/">
          <img
            src="/Content/images/logos/IntegrationManager logo-light.png"
            alt="Evidence Library Integration Manager"
          />
        </NavLink>
      </div>
      <div className="header-right">
        <nav hidden={hideMenu()}>
          <ul>
            <li className="search">
              <NavLink
                to="/incidents/search"
                onClick={() => setActiveLink("incidents")}
              >
                <i></i>
                <span>Incidents</span>
              </NavLink>
            </li>
            {genericExportOptions.enabled && (
              <li className="exports">
                {/*eslint-disable-next-line */}
                <a className={activeLink === "exports" ? "active" : ""}>
                  <i></i>
                  <span>Exports</span>
                </a>
                <ul>
                  <li>
                    <Link
                      to="/exports"
                      onClick={() => setActiveLink("exports")}
                    >
                      <span>Export Items</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/exports/history"
                      onClick={() => setActiveLink("exports")}
                    >
                      <span>Export History</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {techSharePublishEnabled && (
              <li>
                {/*eslint-disable-next-line */}
                <a className={activeLink === "cases" ? "active" : ""}>
                  <i></i>
                  <span>Cases</span>
                </a>
                <ul>
                  <li>
                    <Link to="/cases/" onClick={() => setActiveLink("cases")}>
                      <span>Publish Cases</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cases/publishhistory"
                      onClick={() => setActiveLink("cases")}
                    >
                      <span>Case Publish History</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <li>
              {/*eslint-disable-next-line */}
              <a className={activeLink === "officermapping" ? "active" : ""}>
                <i></i>
                <span>Admin</span>
              </a>
              <ul>
                <li>
                  <Link
                    to="/officermappings/entry"
                    onClick={() => setActiveLink("officermapping")}
                  >
                    <i className="icon-config"></i>
                    <span>Officer Mapping Entry</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a href={elUrl}>
                <i></i>
                <span>VideoManager EL</span>
              </a>
            </li>
            <li className="user-account">
              {/* eslint-disable-next-line */}
              <a style={{ background: "transparent !important" }}>
                <span className="icon-user"></span>{" "}
                <span id="userName">{userName}</span>
              </a>
            </li>
            <li className="user-logout">
              {/* eslint-disable-next-line */}
              <a href="#" onClick={() => gotoElUrl("/Account/LogOff")}>
                <span className="icon-logout"></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

AppHeader.propTypes = { integrationEnabled: PropTypes.bool.isRequired };

const mapStateToProps = (state) => {
  const {
    error,
    integrationEnabled,
    techSharePublishEnabled,
    genericExportOptions,
    userName,
  } = state;

  return {
    error,
    integrationEnabled,
    techSharePublishEnabled,
    genericExportOptions,
    userName,
  };
};

const ConnectedAppHeader = connect(mapStateToProps)(AppHeader);

export default ConnectedAppHeader;
