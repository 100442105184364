import React, { useState } from "react";
import { connect } from "react-redux";
import { submitGenericExportRequest } from "../../Store/mutations";

const ExportInputForm = ({
  setShowModal,
  selectedExportId,
  selectedCaseNumber,
  exportType,
  destinationType,
  createGenericExportRequest,
}) => {
  const [sent, setSent] = useState(false);
  const [label, setLabel] = useState(selectedCaseNumber ?? selectedExportId);
  const inputFieldsAreFilled = () => {
    return selectedExportId.length > 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  const handleKeyPress = (key) => {
    if (inputFieldsAreFilled()) {
      if (key === "Enter") {
        submit();
      }
    }
  };

  const submit = () => {
    setSent(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <ExportInputFormPure
      sourceId={selectedExportId}
      setShowModal={setShowModal}
      exportType={exportType}
      destinationType={destinationType}
      sent={sent}
      label={label}
      setLabel={setLabel}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleKeyPress={handleKeyPress}
      inputFieldsAreFilled={inputFieldsAreFilled}
      createGenericExportRequest={createGenericExportRequest}
    />
  );
};

const mapStateToProps = (state) => ({
  exportType: state.genericExportOptions.selectedType,
  destinationType: state.genericExportOptions.selectedDestination,
});
const mapDispatchToProps = (dispatch) => ({
  createGenericExportRequest: (destinationType, exportType, sourceId, label) =>
    dispatch(
      submitGenericExportRequest(destinationType, exportType, sourceId, label)
    ),
});

const ConnectedExportInputForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportInputForm);

export default ConnectedExportInputForm;

export const ExportInputFormPure = ({
  sourceId,
  sent,
  handleClose,
  handleSubmit,
  exportType,
  destinationType,
  label,
  setLabel,
  setShowModal,
  inputFieldsAreFilled,
  createGenericExportRequest,
}) => {
  exportType = exportType.charAt(0).toUpperCase() + exportType.slice(1);
  return (
    <>
      <div className="modalBackground" onClick={() => setShowModal(false)} />
      {!sent ? (
        <div className="exportsContainer">
          <div className="formGroup">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3 exportsPublishForm">
                <div className="textInputGroup">
                  <div className="textInputSet">
                    <label htmlFor="exportId" className="idInputLabel">
                      {exportType} Id
                    </label>
                    {sourceId}
                  </div>

                  <div className="textInputSet">
                    <label className="idInputLabel">Label</label>
                    <input
                      className="exportsIdInput"
                      value={label}
                      onChange={(e) => {
                        setLabel(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>

                <div className="buttonSet">
                  <button
                    type="Submit"
                    value="Submit"
                    className="btn btn-outline-primary exportSubmitBtn"
                    disabled={!inputFieldsAreFilled()}
                    onClick={() =>
                      createGenericExportRequest(
                        destinationType,
                        exportType,
                        sourceId,
                        label
                      )
                    }
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    value="cancel"
                    className="btn btn-outline-secondary exportSubmitBtn"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="exportsContainer">
          <div className="formGroup">
            <div className="form-group mb-3 exportPublishSent">
              <div className="submittedText">
                Export Id: {sourceId} has been submitted.
              </div>

              <div className="buttonSet">
                <button
                  type="Button"
                  className="btn btn-outline-primary"
                  onClick={() => handleClose()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
