import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Input from "./Input";
import _ from "lodash";

export const InlineInput = ({
  action,
  collection,
  className,
  disabled,
  id,
  placeholder,
  readOnly,
  name,
  tooltip,
  type,
  value,
}) => (
  <div
    className={classnames("flex flex-middle", className, {
      tooltip: "tooltip",
    })}
  >
    <label className="input-custom">
      <Input
        action={action}
        collection={collection}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        value={value}
      />
      <span>{_.startCase(id)}</span>
    </label>
    {tooltip && (
      <span id={`qtip${id}`} className="infocon" title={tooltip}></span>
    )}
  </div>
);

InlineInput.propTypes = {
  action: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf([
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]).isRequired,
  value: PropTypes.any,
};

export default InlineInput;
