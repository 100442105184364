import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IncidentResponseRow from "./IncidentResponseRow";

export const IncidentResponses = ({ incidentResponses }) => (
    <table className="response-table">
        <thead>
        <tr>
            <th className="col-big">{JSON.parse(localStorage.getItem("AgencyConfig")).taggingOptions.incidentNumberTagName}</th>
            <th className="col-dt">Dispatch</th>
            <th className="col-dt">Clear</th>
            <th className="col-big">Officer Names</th>
            <th className="col-big">Unit</th>
        </tr>
        </thead>
        <tbody>
        {incidentResponses.map((incidentResponse, index) =>
            <IncidentResponseRow key={index} id={index} />
        )}
        </tbody>
    </table>
);

IncidentResponses.propTypes = { incidentResponses: PropTypes.array.isRequired };

const mapStateToProps = state => ({ incidentResponses: state.incidentResponses });

const ConnectedIncidentResponses = connect(mapStateToProps)(IncidentResponses);

export default ConnectedIncidentResponses;
