import React from "react";
import Home from "../Home/Home";
import Error from "../Error/Error";
import CasePublishHistory from "../Cases/CasePublishHistory";
import CasesPage from "../Cases/CasesPage";
import ExportsPage from "../Export/ExportItemsPage/ExportsPage";
import ExportHistoryPage from "../Export/ExportHistoryPage/ExportHistoryPage";
import Search from "../Incidents/Search";
import Officers from "../OfficerMappings/Officers";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import GetIntegration from "./GetIntegration/GetIntegration";

const LandingPage = ({ integrationEnabled, error }) => {
  if (!integrationEnabled && error.message === undefined)
    return (
      <div className="page-host">
        <GetIntegration />
      </div>
    );

  return (
    <div className="page-host">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/NoAgency" element={<GetIntegration />} />
        {/* <Route path="/error?token=No%20Token" element={<Error />} /> */}
        <Route path="/error" element={<Error />} />
        <Route path="/exports" element={<ExportsPage />} />
        <Route path="/exports/history" element={<ExportHistoryPage />} />
        <Route path="/cases" element={<CasesPage />} />
        <Route path="/cases/publishHistory" element={<CasePublishHistory />} />
        <Route path="/incidents/search" element={<Search />} />
        <Route path="/officermappings/entry" element={<Officers />} />
      </Routes>
    </div>
  );
};

const mapStateToProps = (state) => ({
  integrationEnabled: state.integrationEnabled,
  error: state.error,
});

export default connect(mapStateToProps)(LandingPage);
