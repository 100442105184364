import React from "react";
import { updatePageFilter } from "../Store/mutations";
import { connect } from "react-redux";

export const CaseHistoryFilter = ({ updatePageFilter }) => {
  return (
    <div id="content-header" className="casesHeader">
      <div className="filterInput">
        <input
          type="string"
          className="form-control col-sm-4 caseNumberInput"
          id="filter"
          placeholder="Filter..."
          aria-label="Case Number"
          aria-describedby="basic-addon2"
          onChange={(e) => updatePageFilter("caseHistory", e.target.value)}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updatePageFilter: (pageName, filter) =>
    dispatch(updatePageFilter(pageName, filter)),
});

const ConnectedCaseHistoryFilter = connect(
  null,
  mapDispatchToProps
)(CaseHistoryFilter);

export default ConnectedCaseHistoryFilter;
