import { sortOrderOptions } from "./OfficerSortingOptions";

const officerListSorting = (officers, sortBy, sortOrder) => {
  var sortedOfficers = [...officers];

  switch (sortOrder) {
    case sortOrderOptions.descending:
      sortedOfficers = [...sortedOfficers].sort((b, a) => {
        if (a[sortBy] === null || a[sortBy] === "") return -1;
        if (b[sortBy] === null || b[sortBy] === "") return 1;
        return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
      });
      break;
    case sortOrderOptions.ascending:
    default:
      sortedOfficers = [...sortedOfficers].sort((a, b) => {
        if (a[sortBy] === null || a[sortBy] === "") return 1;
        if (b[sortBy] === null || b[sortBy] === "") return -1;
        return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
      });
  }

  return sortedOfficers;
};

export default officerListSorting;
