import React from "react";
import CaseTable from "./CaseTable";
import "./Cases.css";
import { connect } from "react-redux";

export const CaseSearchResults = ({ cases }) => {
  return (
    <div id="content-outer-wrapper" className="flex flex-expand">
      <div className="flex" style={{ width: "100%" }}>
        <div id="cases-content-wrapper">
          <CaseTable cases={cases} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cases: state.cases,
});

const ConnectedCaseSearchResults = connect(mapStateToProps)(CaseSearchResults);

export default ConnectedCaseSearchResults;
