import { connect } from "react-redux";
import { BackButton } from "../Common/BackButton";

const ErrorMessageBox = (error) => {
  const errorMessage = error.error.message;
  return (
    <>
      <div
        id="error-description"
        className="margin-bottom"
        style={{
          fontSize: "15px",
          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        }}
      >
        Something happened while processing your request. Please try again. If
        the problem continues, contact your system administrator.
        <br />
        <br />
        {errorMessage && <b>Error Details: </b>}
        <em>{errorMessage && errorMessage}</em>
      </div>
      <BackButton />
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.error,
});

const connectedErrorMessageBox = connect(mapStateToProps)(ErrorMessageBox);

export default connectedErrorMessageBox;
