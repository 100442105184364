import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducer";
import * as sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer,
  middleware: [
    //createLogger(),
    createLogger({
      predicate: (getState, action) =>
        action.type === "API_ERROR" || action.type === "AGENCY_CONFIG_ERROR",
    }),
    sagaMiddleware,
  ],
});

for (let saga in sagas) {
  sagaMiddleware.run(sagas[saga]);
}
