import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IncidentEventRow from "./IncidentEventRow";
import "./Incidents.css";

export const IncidentEvents = ({ incidentEvents }) => {
  return (
    <table className="response-table" id="event-table">
      <thead>
        <tr>
          <th className="col-small"></th>
          <th className="col-med">Event</th>
          <th className="col-small"></th>
          <th className="col-med eventcriteria">
            {
              JSON.parse(localStorage.getItem("AgencyConfig")).taggingOptions
                .incidentNumberTagName
            }
          </th>
          <th className="col-med start eventcriteria">Record Start</th>
          <th className="col-med rstop eventcriteria">Record Stop</th>
          <th className="col-med  eventcriteria">Officer Name</th>
          <th className="col-med">Device</th>
          <th className="col-med">Event Category</th>
          <th className="col-med">Actions</th>
        </tr>
      </thead>
      <tbody id="event-table">
        {incidentEvents.map((incidentEvent, index) => (
          <IncidentEventRow id={incidentEvent.id} key={index} />
        ))}
      </tbody>
    </table>
  );
};

IncidentEvents.propTypes = { incidentEvents: PropTypes.array.isRequired };

const mapStateToProps = (state) => ({ incidentEvents: state.incidentEvents });

const ConnectedIncidentEvents = connect(mapStateToProps)(IncidentEvents);

export default ConnectedIncidentEvents;
