import React, { useState } from "react";
import { submitCasePublishRequest } from "../Store/mutations";
import { connect } from "react-redux";

const CasePublishInputForm = ({
  submitCasePublishRequest,
  setShowModal,
  selectedCaseNumber,
}) => {
  const techShareReqId = selectedCaseNumber;
  const [sent, setSent] = useState(false);
  const inputFieldsAreFilled = () => {
    return selectedCaseNumber.length > 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  const handleKeyPress = (key) => {
    if (inputFieldsAreFilled()) {
      if (key === "Enter") {
        submit();
      }
    }
  };

  const submit = () => {
    submitCasePublishRequest(selectedCaseNumber, techShareReqId);
    setSent(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <CasePublishInputFormPure
      selectedCaseNumber={selectedCaseNumber}
      setShowModal={setShowModal}
      sent={sent}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleKeyPress={handleKeyPress}
      inputFieldsAreFilled={inputFieldsAreFilled}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  submitCasePublishRequest: (caseNumber, techShareReqId) =>
    dispatch(submitCasePublishRequest(caseNumber, techShareReqId)),
});

const ConnectedCasePublishInputForm = connect(
  null,
  mapDispatchToProps
)(CasePublishInputForm);

export default ConnectedCasePublishInputForm;

export const CasePublishInputFormPure = ({
  selectedCaseNumber,
  sent,
  handleClose,
  handleSubmit,
  handleKeyPress,

  setShowModal,
  inputFieldsAreFilled,
}) => {
  return (
    <>
      <div className="modalBackground" onClick={() => setShowModal(false)} />
      {!sent ? (
        <div className="caseContainer">
          <div className="formGroup">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3 casePublishForm">
                <div className="textInputGroup">
                  <div className="textInputSet">
                    <label htmlFor="caseNumber" className="idInputLabel">
                      Case Number
                    </label>
                    <input
                      type="string"
                      className="form-control col-sm-4 caseNumberInput"
                      id="caseNumber"
                      placeholder="Enter Case Number"
                      value={selectedCaseNumber}
                      aria-label="Case Number"
                      aria-describedby="basic-addon2"
                      onKeyDownCapture={(e) => {
                        e.preventDefault();
                        handleKeyPress(e.key);
                      }}
                    />
                  </div>
                </div>

                <div className="buttonSet">
                  <button
                    type="Submit"
                    value="Submit"
                    className="btn btn-outline-primary caseSubmitBtn"
                    disabled={!inputFieldsAreFilled()}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    value="cancel"
                    className="btn btn-outline-secondary caseSubmitBtn"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="caseContainer">
          <div className="formGroup">
            <div className="form-group mb-3 casePublishSent">
              <div className="submittedText">
                Case Number {selectedCaseNumber} has been submitted.
              </div>

              <div className="buttonSet">
                <button
                  type="Button"
                  className="btn btn-outline-primary"
                  onClick={() => handleClose()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
