import { BackButton } from "../../Common/BackButton";

const GetIntegrationBody = () => {
  return (
    <div id="content-outer-wrapper" className="flex flex-expand">
      <div id="content-wrapper" className="full-height-container">
        <section className="section-main no-pad-bottom">
          <div className="section-scroll" style={{ background: "#eeeff2" }}>
            <h1>
              Please contact your Motorola representative to sign up for
              Integration Services
            </h1>
            <BackButton />
          </div>
        </section>
      </div>
    </div>
  );
};

export default GetIntegrationBody;
