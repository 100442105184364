import axios from "axios";
import { store } from "./Store/Index";
import { apiError } from "./Store/mutations";
let api = null;
let controller;

const getInitializeApi = () => {
  if (api) {
    return api;
  }

  api = axios.create({
    baseURL: window.config.managerApiUrl,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
      Accept: "application/problem+json; application/json",
    },

    withCredentials: true,
    crossDomain: true,
  });

  api.interceptors.request.use(handleSuccess, handleError);
  api.interceptors.response.use(handleSuccess, handleError);
  return api;
};

const handleSuccess = (response) => response;

const handleError = (error) => {
  if (error.response === undefined) {
    error.message = "Unable to communicate with API, no response received";
  }

  if (error.code === "ErrCanceled") {
    return Promise.reject(error);
  }

  store.dispatch(apiError(error));
  if (
    window.location.pathname !== "/error" &&
    window.location.pathname !== "/noagency"
  ) {
    window.location.pathname = "/error";
  }

  return Promise.reject(error);
};

export const handleAbortApiCall = () => {
  if (controller) {
    controller.abort();
  }
};

export const post = async (url, data, callback) =>
  await getInitializeApi().post(url, data).then(callback);

export const get = async (url, callback) => {
  controller = new AbortController();
  return await getInitializeApi().get(url).then(callback);
};

export const put = async (url, data, callback) =>
  await getInitializeApi().put(url, data).then(callback);

export const del = async (url, callback) =>
  await getInitializeApi().delete(url).then(callback);
