import React from "react";
import GetIntegrationBody from "./GetIntegrationBody";
import GetIntegrationHeader from "./GetIntegrationHeader";

const GetIntegration = () => {
  return (
    <div id="main-container">
      <GetIntegrationHeader />
      <GetIntegrationBody />
    </div>
  );
};

export default GetIntegration;
