import { connect } from "react-redux";

export const ExportsLoadingMessage = ({ display, loadingMessage }) => {
  return (
    <div className="loadingMessage">
      <h1>
        {loadingMessage ? (
          <span className="secondary">
            <i className="icon-spinner-alt icon-spin" />
            {loadingMessage} . . .
          </span>
        ) : (
          <span>
            <i className="icon-search" />
            {display}
          </span>
        )}
      </h1>
    </div>
  );
};

export const displayLoadingText = ({ selectedType, cases, incidentEvents }) => {
  if (selectedType === "default") {
    return "Please select an export type";
  }
  const sBuild = (type, count) => `${count} ${type}${count === 1 ? "" : "s"} `;
  var typeLength = 0;
  switch (selectedType) {
    case "case":
      typeLength = cases.length;
      break;
    case "event":
      typeLength = incidentEvents.length;
      break;
    default:
      break;
  }
  let disp = sBuild(selectedType, typeLength);
  return disp.concat(" displayed");
};

const mapStateToProps = (state) => ({
  display: displayLoadingText({
    selectedType: state.genericExportOptions.selectedType,
    cases: state.cases,
    incidentEvents: state.incidentEvents,
  }),
  loadingMessage: state.loadingMessage,
});

const ConnectedExportLoadingMessage = connect(mapStateToProps)(
  ExportsLoadingMessage
);

export default ConnectedExportLoadingMessage;
