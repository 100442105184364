import ExportRowExportButton from "../ExportRowExportButton";
import moment from "moment";
import gotoElUrl from "../../../gotoElUrl";
const ExportCasesTableRow = ({ casesData, openPublishModal }) => {
  const {
    id,
    caseNumber,
    createdByName,
    createdOn,
    eventCount,
    linkCount,
    fileCount,
  } = casesData;

  const handleClick = () => {
    openPublishModal(id, caseNumber);
  };

  const hasItems = () => {
    var hasItems = eventCount + linkCount + fileCount > 0;
    return hasItems;
  };

  let caseDetailsUri = `/#caseDetails/${id}`;
  const idIsValid = () => {
    return typeof id === "string" && id.length > 0;
  };
  return (
    <tr>
      <td>{caseNumber}</td>
      <td>{id}</td>
      <td>{createdByName}</td>
      <td>{moment.utc(createdOn).local().format("yyyy-MM-DD HH:mm:ss")}</td>
      <td>{eventCount}</td>
      <td>
        <ExportRowExportButton
          handleClick={handleClick}
          isDisabled={!hasItems()}
        />
      </td>
      <td>
        <a
          href={gotoElUrl(caseDetailsUri)}
          target="_blank"
          rel="noreferrer"
          className="btn btn-primary"
        >
          <button
            className={!idIsValid() ? "disabled" : ""}
            disabled={!idIsValid()}
          >
            Details
          </button>
        </a>
      </td>
    </tr>
  );
};

export default ExportCasesTableRow;
