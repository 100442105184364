import React from "react";
import CaseHistoryFilter from "./CaseHistoryFilter";
import CaseHistoryTable from "./CaseHistoryTable";
import CaseHistoryPagination from "./CaseHistoryPagination";
import { connect } from "react-redux";
import "./Cases.css";
import { requestCaseHistory } from "../Store/mutations";
import { useEffect } from "react";

export const CasePublishHistory = ({
  techSharePublishEnabled,
  requestCaseHistory,
  caseHistoryFilter,
  caseHistory,
  loadingMessage,
}) => {
  useEffect(() => {
    requestCaseHistory();
  }, [requestCaseHistory]);

  if (!techSharePublishEnabled) {
    window.location.pathname = "/";
  }

  var filteredCases = [
    ...caseHistory.filter((caseData) =>
      Object.values(caseData).some(
        (value) =>
          value !== null &&
          value.toString().toLowerCase().includes(caseHistoryFilter)
      )
    ),
  ];

  return (
    <CasePublishHistoryPure
      filteredCases={filteredCases}
      loadingMessage={loadingMessage}
    />
  );
};

const mapStateToProps = (state) => ({
  caseHistory: state.caseHistory,
  caseHistoryFilter: state.pageControls.caseHistory.pageFilter,
  techSharePublishEnabled: state.techSharePublishEnabled,
  pageNumber: state.pageControls.caseHistory.pageNumber,
  pageSize: state.pageControls.caseHistory.pageSize,
  loadingMessage: state.loadingMessage,
});

const mapDispatchToProps = (dispatch) => ({
  requestCaseHistory: () => dispatch(requestCaseHistory()),
});

const ConnectedCasePublishHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(CasePublishHistory);

export default ConnectedCasePublishHistory;

export const CasePublishHistoryPure = ({ filteredCases, loadingMessage }) => {
  return (
    <div id="cases-content-outer-wrapper">
      <div id="content-header-container" className="caseHistoryHeader">
        <div className="caseHistoryHeaderLeft">
          {loadingMessage ? (
            <div className="caseHistorySearchMessage">
              <h1>
                <i className="icon-spinner-alt icon-spin" />
                {loadingMessage} . . .
              </h1>
            </div>
          ) : (
            <div className="caseHistorySearchMessage">
              <h1>
                <i className="icon-search" />
                {`${filteredCases.length} item${
                  filteredCases.length === 1 ? "" : "s"
                } found in Case Publish History`}
              </h1>
            </div>
          )}
        </div>
        <div className="caseHistoryHeaderRight">
          <CaseHistoryPagination />
          <CaseHistoryFilter />
        </div>
      </div>
      <CaseHistoryTable caseHistory={filteredCases} />
    </div>
  );
};
