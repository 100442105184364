import React from "react";
import ErrorHeader from "./ErrorHeader";
import ErrorBody from "./ErrorBody";
import "./error.css";
const Error = () => {
  return (
    <div id="main-container">
      <ErrorHeader />
      <ErrorBody />
    </div>
  );
};

export default Error;
