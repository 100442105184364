const defaultState = {
  caseCriteria: {
    caseNumber: "",
  },
  cases: [],
  caseHistory: [],
  error: {},
  genericExportOptions: {
    enabled: false,
    configurationIds: [],
    types: { case: false, event: false },
    selectedDestination: "default",
    selectedType: "default",
  },
  exportHistory: [],
  exportHistoryOptions: {
    selectedStatusHistory: "all",
    selectedDestinationHistory: "all",
  },
  incidentCriteria: {
    incidentNumber: "",
    incidentDate: "",
  },
  incidentEvents: [],
  incidentEventSearchFilter: {
    officer: undefined,
    includeUnknown: false,
    existingMatches: true,
    includeLinked: true,
    startTimeOffsetInMinutes: undefined,
    stopTimeOffsetInMinutes: undefined,
  },
  incidentEventForExportSearchCriteria: {
    officerIds: "",
    searchDateBegin: "",
    searchDateEnd: "",
  },
  incidentNumber: "",
  incidentResponseId: null,
  incidentResponses: [],
  incidents: [],
  integrationEnabled: false,
  loadingMessage: false,
  officers: [],
  pageControls: {
    case: {
      pageItemCount: 0,
      pageNumber: 1,
      pageSize: 50,
    },
    caseHistory: {
      pageFilter: "",
      pageNumber: 1,
      pageSize: 50,
    },
    exports: {
      pageFilter: "",
      pageNumber: 1,
      pageSize: 50,
    },
    exportHistory: {
      pageFilter: "",
      pageNumber: 1,
      pageSize: 50,
      recievedAllHistory: false,
    },
    incident: {
      pageItemCount: 0,
      pageNumber: 1,
      pageSize: 50,
    },
    officer: {
      pageFilter: "",
      pageNumber: 1,
      pageSize: 50,
    },
  },
  secondaryTags: {
    incidentNumber: "",
    rmsNumber: "",
    natureCode: "",
  },
  taggingOptions: {
    disableMultiTag: false,
    secondaryTagMultiTagDelimiter: ",",
    incidentNumberTagName: "",
    rmsNumberTagName: "",
    natureCodeTagName: "",
  },
  techSharePublishEnabled: false,
  userName: "Authorized User",
};

export default defaultState;
