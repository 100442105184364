import { BackButton } from "../Common/BackButton";

const NoTokenErrorMessageBox = () => {
  return (
    <>
      <div
        id="error-description"
        className="margin-bottom"
        style={{
          fontSize: "15px",
          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        }}
      >
        An error occured: User does not have required permissions or is not an
        administrator. Please contact your administrator to review Security
        options on the Organization Management screen
        <br />
        <br />
        <div>
          <b>Required permissions:</b>
          <ul>
            <li>EnhancedSearch</li>
            <li>EditRecordEventProperties</li>
            <li>User</li>
            <li>Unassigned</li>
          </ul>
        </div>
      </div>

      <BackButton />
    </>
  );
};

export default NoTokenErrorMessageBox;
