import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestUpdateOfficerMapping } from "../Store/mutations";
import "./Officers.css";

export const OfficerRow = ({
  officer,
  onUpdateOfficerMapping,
  existingCadIdList,
}) => {
  const [cadId, setCadId] = useState("");

  const OnChangeHandle = (value) => {
    setCadId(value);
  };

  return (
    <OfficerRowPure
      cadId={cadId}
      officer={officer}
      onUpdateOfficerMapping={onUpdateOfficerMapping}
      existingCadIdList={existingCadIdList}
      OnChangeHandle={OnChangeHandle}
    />
  );
};

OfficerRow.propTypes = {
  officer: PropTypes.shape({
    dispatchOfficerId: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    badge: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  onUpdateOfficerMapping: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  officer: ownProps.officer,
  existingCadIdList: state.officers
    .map((officer) => officer.dispatchOfficerId)
    .filter((dispatchOfficerId) => dispatchOfficerId !== null),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateOfficerMapping: (event) => {
    event.preventDefault();
    dispatch(
      requestUpdateOfficerMapping(
        event.target.form.elements.dispatchOfficerId.value,
        ownProps.id
      )
    );
  },
});

const ConnectedOfficerRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfficerRow);

export default ConnectedOfficerRow;

export const OfficerRowPure = ({
  cadId,
  officer,
  existingCadIdList,
  onUpdateOfficerMapping,
  OnChangeHandle,
}) => {
  return (
    <tr aria-label="OfficerRow">
      <td className="col-cadId">
        <form>
          <table>
            <tbody>
              <tr>
                <td>
                  <input
                    id="dispatchOfficerId"
                    type="text"
                    placeholder={officer.dispatchOfficerId}
                    onChange={(evt) => OnChangeHandle(evt.target.value)}
                  />
                  {existingCadIdList.includes(cadId) && cadId !== "" ? (
                    <p style={{ color: "red" }}>
                      This CAD ID is already in use
                    </p>
                  ) : (
                    ""
                  )}
                </td>
                <td className="update-button">
                  <button
                    className="cad-id-update-button"
                    onClick={onUpdateOfficerMapping}
                    disabled={existingCadIdList.includes(cadId) && cadId !== ""}
                  >
                    {officer.dispatchOfficerId ? "Update" : "Save"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </td>
      <td className="col-dispatchOfficerId">{officer.dispatchOfficerId}</td>
      <td className="col-lastName">{officer.lastName}</td>
      <td className="col-firstName">{officer.firstName}</td>
      <td className="col-middleName">{officer.middleName}</td>
      <td className="col-badge">{officer.badge}</td>
      <td className="col-title">{officer.title}</td>
    </tr>
  );
};
