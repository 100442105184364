import { sortOrderOptions } from "./CaseSortingOptions";

const CaseListSorting = (cases, sortBy, sortOrder) => {
  var sortedCases = [...cases];

  switch (sortOrder) {
    case sortOrderOptions.descending:
      sortedCases = [...sortedCases].sort((b, a) => {
        if (typeof a[sortBy] !== "string" || a[sortBy] === "") return -1;
        if (typeof b[sortBy] !== "string" || b[sortBy] === "") return 1;
        return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
      });
      break;
    case sortOrderOptions.ascending:
    default:
      sortedCases = [...sortedCases].sort((a, b) => {
        if (typeof a[sortBy] !== "string" || a[sortBy] === "") return 1;
        if (typeof b[sortBy] !== "string" || b[sortBy] === "") return -1;
        return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
      });
  }

  return sortedCases;
};

export default CaseListSorting;
