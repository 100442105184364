import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import Input from "./Input";
import _ from "lodash";

export const BlockInput = ({
  action,
  collection,
  disabled,
  id,
  isEmpty,
  label,
  labelClass,
  placeholder,
  readOnly,
  tooltip,
  type,
  uiDestressorTimeout,
  value,
  onClear,
  sanitize,
}) => (
  <React.Fragment>
    <div className="block"></div>
    <div className={classnames("flex flex-middle", { tooltip: tooltip })}>
      <label className={classnames("align-left", labelClass)}>
        {label || _.startCase(id)}
      </label>
      {tooltip && (
        <span id={`qtip${id}`} className="infocon" title={tooltip}></span>
      )}
      {!isEmpty && ( // eslint-disable-next-line
        <a className="clear-search-param flex-right" onClick={onClear}>
          <span className="icon-close-circle"></span>
        </a>
      )}
    </div>
    <Input
      action={action}
      collection={collection}
      disabled={disabled}
      id={id}
      placeholder={placeholder}
      readOnly={readOnly}
      type={type}
      uiDestressorTimeout={uiDestressorTimeout}
      value={value}
      sanitize={sanitize}
    />
  </React.Fragment>
);

BlockInput.propTypes = {
  action: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf([
    "button",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]).isRequired,
  uiDestressorTimeout: PropTypes.number,

  onClear: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const value = state[ownProps.collection][ownProps.id];
  return {
    ...ownProps,
    isEmpty: !value && value !== 0,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClear: () =>
    dispatch({
      type: ownProps.action,
      name: ownProps.id,
    }),
});

const ConnectedBlockInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockInput);

export default ConnectedBlockInput;
