const useGetEventUrls = (incidentEvent) => {
  const ELUrl = JSON.parse(
    localStorage.getItem("AgencyConfig")
  ).evidenceLibraryUrl;

  const playbackUrl =
    ELUrl +
    "#eventPlayback/" +
    incidentEvent.id.replace(/-/g, "") +
    "?autoplay=true";

  const detailUrl =
    ELUrl + "#eventDetails/" + incidentEvent.id.replace(/-/g, "");

  return { playbackUrl, detailUrl };
};
export default useGetEventUrls;
