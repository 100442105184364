const gotoElUrl = (path) => {
  var agencyConfig = localStorage.getItem("AgencyConfig");
  if (!agencyConfig) {
    return "/";
  }

  agencyConfig = JSON.parse(agencyConfig);
  if (agencyConfig.evidenceLibraryUrl) {
    var href = agencyConfig.evidenceLibraryUrl.replace(/\/+$/, "");

    return path ? href + path : href;
  } else {
    return "/";
  }
};

export default gotoElUrl;
