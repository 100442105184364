import { connect } from "react-redux";
import { applyExportHistoryFilter } from "./ExportHistoryFilter";

export const ExportHistoryLoadingMessage = ({ display, loadingMessage }) => {
  return (
    <div className="loadingMessage">
      <h1>
        {loadingMessage ? (
          <span className="secondary">
            <i className="icon-spinner-alt icon-spin" />
            {loadingMessage} . . .
          </span>
        ) : (
          <span>
            <i className="icon-search" />
            {display}
          </span>
        )}
      </h1>
    </div>
  );
};

export const displayLoadingText = (exportHistory, filter) => {
  const sBuild = (type, count) => `${count} ${type}${count === 1 ? "" : "s"} `;
  var historyLength =
    filter.length === 0
      ? exportHistory.length
      : applyExportHistoryFilter(exportHistory, filter).length;

  let disp = sBuild("Export", historyLength);
  return disp.concat(" Displayed");
};

const mapStateToProps = (state) => ({
  display: displayLoadingText(
    state.exportHistory,
    state.pageControls.exportHistory.pageFilter
  ),
  loadingMessage: state.loadingMessage,
});

const ConnectedExportHistoryLoadingMessage = connect(mapStateToProps)(
  ExportHistoryLoadingMessage
);

export default ConnectedExportHistoryLoadingMessage;
