import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import PropTypes from "prop-types";
import useIncidentPageControls from "./useIncidentPageControls";
import "./Incidents.css";

export const SearchHeader = ({
  display,
  loadingMessage,
  pageNumber,
  pageSize,
  incidentCount,
}) => {
  const { handleIncidentPageChange, handleIncidentPageSizeChange } =
    useIncidentPageControls();

  const numberOfPages =
    incidentCount === undefined
      ? 1
      : Math.ceil(incidentCount < pageSize ? 1 : incidentCount / pageSize);

  const validatePageNumber = (page) => {
    page = Math.max(0, page);
    page = Math.min(page, numberOfPages);

    return page;
  };

  useEffect(() => {
    if (incidentCount <= 0) {
      return;
    }
    if (pageNumber > numberOfPages) {
      handleIncidentPageChange(Math.ceil(numberOfPages));
    }
    if (pageNumber === 0) {
      handleIncidentPageChange(1);
    }
  }, [
    incidentCount,
    numberOfPages,
    pageSize,
    pageNumber,
    display,
    handleIncidentPageChange,
  ]);

  return (
    <SearchHeaderPure
      loadingMessage={loadingMessage}
      pageNumber={pageNumber}
      numberOfPages={numberOfPages}
      incidentCount={incidentCount}
      pageSize={pageSize}
      display={display}
      handleIncidentPageChange={handleIncidentPageChange}
      handleIncidentPageSizeChange={handleIncidentPageSizeChange}
      validatePageNumber={validatePageNumber}
    />
  );
};

SearchHeader.propTypes = {
  display: PropTypes.string.isRequired,
  loadingMessage: PropTypes.any.isRequired,
};

const displayLoadingText = (state) => {
  const sBuild = (type, count) => `${count} ${type}${count === 1 ? "" : "s"} `;
  let disp = "";
  if (state.pageControls.incident.pageItemCount === undefined) {
    return (disp =
      "Retrieving Total Count of " +
      JSON.parse(localStorage.getItem("AgencyConfig")).taggingOptions
        .incidentNumberTagName);
  } else {
    disp = sBuild(
      JSON.parse(localStorage.getItem("AgencyConfig")).taggingOptions
        .incidentNumberTagName,
      state.pageControls.incident.pageItemCount
    );
  }
  if (state.incidentNumber) {
    disp = disp.concat(
      "> ",
      sBuild("Response", state.incidentResponses.length)
    );
  }
  if (!!state.incidentResponses[state.incidentResponseId]) {
    disp = disp.concat("> ", sBuild("Event", state.incidentEvents.length));
  }
  return disp.concat(" Found");
};

const mapStateToProps = (state) => {
  return {
    display: displayLoadingText(state),
    loadingMessage: state.loadingMessage,
    incidentCount: state.pageControls.incident.pageItemCount,
    pageSize: state.pageControls.incident.pageSize,
    pageNumber: state.pageControls.incident.pageNumber,
  };
};

const ConnectedSearchHeader = connect(mapStateToProps)(SearchHeader);

export default ConnectedSearchHeader;

export const SearchHeaderPure = ({
  loadingMessage,
  pageNumber,
  numberOfPages,
  incidentCount,
  pageSize,
  display,
  handleIncidentPageChange,
  handleIncidentPageSizeChange,
  validatePageNumber,
}) => {
  return (
    <div id="content-header-container" className="indented">
      <div id="content-header" className="flex">
        <div className="loadingMessage">
          <h1>
            {loadingMessage ? (
              <span className="secondary">
                <i className="icon-spinner-alt icon-spin" />
                {loadingMessage} . . .
              </span>
            ) : (
              <span>
                <i className="icon-search" />
                {display}
              </span>
            )}
          </h1>
        </div>

        <div className="flex-right paginationControl">
          <div className="flex flex-middle flex-auto flex-margin-small">
            <input
              aria-label="pageNumberInput"
              className="paginationInput pagination"
              type="number"
              value={pageNumber}
              min={1}
              step="1"
              max={!isNaN(numberOfPages) ? numberOfPages : 1}
              onChange={(e) => {
                handleIncidentPageChange(validatePageNumber(e.target.value));
              }}
            />
            <span className="fancy pagination">of</span>
            <span className="pagination">
              {!isNaN(numberOfPages) ? numberOfPages : 1}
            </span>
          </div>
          <div className="pageChangeArrows">
            <i
              aria-label="pageDownButton"
              disabled={pageNumber <= "1"}
              className={`icon icon-chevron-left tip-top  ${
                pageNumber <= "1" ? "disabled" : ""
              }`}
              onClick={() => {
                handleIncidentPageChange(pageNumber - 1);
              }}
            />
            <i
              aria-label="pageUpButton"
              disabled={pageNumber >= incidentCount / pageSize}
              className={`icon icon-chevron-right tip-top ${
                pageNumber >= incidentCount / pageSize ? "disabled" : ""
              }`}
              onClick={() => {
                handleIncidentPageChange(pageNumber + 1);
              }}
            />
          </div>
          <select
            aria-label="pageSizeBox"
            defaultValue={"50"}
            onChange={(e) => {
              handleIncidentPageSizeChange(e.target.value);
            }}
          >
            <option value="50">50 Per Page</option>
            <option value="100">100 Per Page</option>
            <option value="250">250 Per Page</option>
          </select>
        </div>
      </div>
    </div>
  );
};
