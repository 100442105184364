import React from "react";

import { connect } from "react-redux";
import ExportsListSorting from "../ExportsListSorting";
import ExportsSortOrderIcon from "../ExportsSortOrderIcon";
import {
  exportHistorySortByOptions,
  exportsSortByOptions,
  sortOrderOptions,
} from "../ExportsSortingOptions";
import { searchGenericExportHistory } from "../../Store/mutations";
import { useState } from "react";
import { applyExportHistoryFilter } from "./ExportHistoryFilter";
import ExportHistoryTableRow from "./ExportHistoryTableRow";
import "../Exports.css";

export const ExportHistoryTable = ({
  exportHistory,
  filter,
  pageNumber,
  pageSize,
  receivedAllHistory,
  searchGenericExportHistory,
}) => {
  var filteredHistory = applyExportHistoryFilter(exportHistory, filter);

  var sortedExportHistory;

  const [sortBy, setSortBy] = useState(exportsSortByOptions.exportId);
  const [sortOrder, setSortOrder] = useState(sortOrderOptions.ascending);

  const handleUpdateExportSort = () => {
    sortedExportHistory = ExportsListSorting(
      filteredHistory,
      sortBy,
      sortOrder
    );
  };

  handleUpdateExportSort();

  const alternateSortOrder = () => {
    if (sortOrder === sortOrderOptions.ascending) {
      setSortOrder(sortOrderOptions.descending);
    } else setSortOrder(sortOrderOptions.ascending);
  };

  const handleSortChange = (sortByColumn) => {
    if (sortByColumn === sortBy) {
      alternateSortOrder();
    } else {
      setSortBy(sortByColumn);
    }
    handleUpdateExportSort();
  };

  if (pageNumber.length === 0) {
    exportHistory = [...exportHistory].slice(0, 1 * pageSize);
  } else
    exportHistory = [...exportHistory].slice(
      (Math.max(pageNumber, 1) - 1) * pageSize,
      pageNumber * pageSize
    );

  return (
    <ExportHistoryTablePure
      sortedExportHistory={sortedExportHistory}
      sortBy={sortBy}
      sortOrder={sortOrder}
      pageSize={pageSize}
      receivedAllHistory={receivedAllHistory}
      handleSortChange={handleSortChange}
      searchGenericExportHistory={searchGenericExportHistory}
    />
  );
};

const mapStateToProps = (state) => ({
  exportHistory: state.exportHistory,
  filter: state.pageControls.exportHistory.pageFilter,
  pageNumber: state.pageControls.exportHistory.pageNumber,
  pageSize: state.pageControls.exportHistory.pageSize,
  receivedAllHistory: state.pageControls.exportHistory.receivedAllHistory,
});

const mapDispatchToProps = (dispatch) => ({
  searchGenericExportHistory: () => dispatch(searchGenericExportHistory()),
});

const ConnectedExportHistoryTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportHistoryTable);
export default ConnectedExportHistoryTable;

export const ExportHistoryTablePure = ({
  sortedExportHistory,
  sortBy,
  sortOrder,
  pageSize,
  receivedAllHistory,
  handleSortChange,
  openPublishModalwithExportId,
  searchGenericExportHistory,
}) => {
  return (
    <div id="map-exports-container">
      <table>
        <thead>
          <tr>
            <th
              onClick={() =>
                handleSortChange(exportHistorySortByOptions.exportId)
              }
            >
              Export ID
              <ExportsSortOrderIcon
                headerName={exportHistorySortByOptions.exportId}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th
              onClick={() => handleSortChange(exportHistorySortByOptions.label)}
            >
              Label
              <ExportsSortOrderIcon
                headerName={exportHistorySortByOptions.label}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th>Source ID</th>
            <th>Events</th>
            <th>Streams</th>
            <th>Destination</th>
            <th
              onClick={() =>
                handleSortChange(exportHistorySortByOptions.createdDate)
              }
            >
              Created Date
              <ExportsSortOrderIcon
                headerName={exportHistorySortByOptions.createdDate}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th
              onClick={() =>
                handleSortChange(exportHistorySortByOptions.completedDate)
              }
            >
              Completed Date
              <ExportsSortOrderIcon
                headerName={exportHistorySortByOptions.completedDate}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th
              onClick={() =>
                handleSortChange(exportHistorySortByOptions.status)
              }
            >
              Status
              <ExportsSortOrderIcon
                headerName={exportHistorySortByOptions.status}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th
              onClick={() =>
                handleSortChange(exportHistorySortByOptions.requestedBy)
              }
            >
              Requested By
              <ExportsSortOrderIcon
                headerName={exportHistorySortByOptions.requestedBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
          </tr>
        </thead>

        <tbody>
          {sortedExportHistory.map((exportHistoryData, index) => {
            return (
              <ExportHistoryTableRow
                exportHistoryData={exportHistoryData}
                key={index}
                openPublishModal={openPublishModalwithExportId}
                searchGenericExportHistory={searchGenericExportHistory}
              />
            );
          })}
        </tbody>
      </table>
      {!receivedAllHistory && (
        <div>
          <div
            className="loadNextLink"
            onClick={() => searchGenericExportHistory()}
            disabled={receivedAllHistory}
          >
            <i className="icon-plus" />
            <h4> Next {pageSize}</h4>
          </div>
        </div>
      )}
    </div>
  );
};
