import React from "react";
import HomeBody from "./HomeBody";
import HomeHeader from "./HomeHeader";

const Home = () => {
  return (
    <div id="main-container">
      <HomeHeader />
      <HomeBody />
    </div>
  );
};

export default Home;
