import { useNavigate } from "react-router-dom";

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="button"
      onClick={() => {
        navigate(-1);
      }}
      style={{ minWidth: "120px" }}
    >
      BACK
    </button>
  );
};
