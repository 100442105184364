import React from "react";
import ExportsSearchHeader from "./ExportsSearchHeader";
import ExportsControlPanel from "./ExportsControlPanel";
import ExportSearchResults from "./ExportsSearchResults";

import "../Exports.css";

export const ExportsPage = () => {
  return (
    <div id="exports-content-outer-wrapper">
      <div id="content-header-container" className="indented">
        <ExportsSearchHeader pageName="export" />
      </div>
      <ExportsControlPanel />
      <ExportSearchResults />
    </div>
  );
};

export default ExportsPage;
