import React from "react";
import PropTypes from "prop-types";
import InlineInput from "./InlineInput";

const InputCheckbox = ({
  action,
  collection,
  className,
  disabled,
  id,
  tooltip,
}) => (
  <InlineInput
    action={action}
    collection={collection}
    className={className}
    disabled={disabled}
    id={id}
    tooltip={tooltip}
    type="checkbox"
  />
);

InputCheckbox.propTypes = {
  action: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default InputCheckbox;
