export const exportsSortByOptions = {
  exportId: "exportId",
  createdByName: "createdByName",
  createdOn: "createdOn",
  caseNumber: "caseNumber",
};

export const exportHistorySortByOptions = {
  exportId: "exportId",
  label: "label",
  destination: "destination",
  createdDate: "createdDate",
  completedDate: "completedDate",
  status: "status",
  requestedBy: "requestedBy",
};

export const sortOrderOptions = {
  ascending: "ascending",
  descending: "descending",
};
