import React from "react";

import ExportCasesTableRow from "./ExportCasesTableRow";
import { connect } from "react-redux";
import ExportsListSorting from "../../ExportsListSorting";
import ExportsSortOrderIcon from "../../ExportsSortOrderIcon";
import {
  exportsSortByOptions,
  sortOrderOptions,
} from "../../ExportsSortingOptions";
import ExportsInputForm from "../ExportsInputForm";
import { useState } from "react";
import "../../Exports.css";

export const ExportCasesTable = ({ cases }) => {
  var sortedCases = [...cases];

  const [sortBy, setSortBy] = useState(exportsSortByOptions.createdOn);
  const [sortOrder, setSortOrder] = useState(sortOrderOptions.descending);
  const [showModal, setShowModal] = useState(false);
  const [selectedExportId, setSelectedExportId] = useState(null);
  const [selectedCaseNumber, setSelectedCaseNumber] = useState(null);

  const handleUpdateExportSort = () => {
    sortedCases = ExportsListSorting(cases, sortBy, sortOrder);
  };

  handleUpdateExportSort();

  const alternateSortOrder = () => {
    if (sortOrder === sortOrderOptions.ascending) {
      setSortOrder(sortOrderOptions.descending);
    } else setSortOrder(sortOrderOptions.ascending);
  };

  const handleSortChange = (sortByColumn) => {
    if (sortByColumn === sortBy) {
      alternateSortOrder();
    } else {
      setSortBy(sortByColumn);
    }
    handleUpdateExportSort();
  };

  const openExportModal = (id, caseNumber) => {
    setSelectedExportId(id);
    setSelectedCaseNumber(caseNumber);
    setShowModal(true);
  };

  return (
    <ExportTablePure
      sortedCases={sortedCases}
      selectedExportId={selectedExportId}
      selectedCaseNumber={selectedCaseNumber}
      showModal={showModal}
      sortBy={sortBy}
      sortOrder={sortOrder}
      handleSortChange={handleSortChange}
      setShowModal={setShowModal}
      openExportModal={openExportModal}
    />
  );
};

const mapStateToProps = (state) => ({
  cases: state.cases,
});

const mapDispatchToProps = (dispatch) => ({});

const ConnectedExportCasesTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportCasesTable);
export default ConnectedExportCasesTable;

export const ExportTablePure = ({
  sortedCases,
  selectedExportId,
  selectedCaseNumber,
  showModal,
  sortBy,
  sortOrder,
  handleSortChange,
  setShowModal,
  openExportModal,
}) => {
  return (
    <div id="map-exports-container">
      <table aria-label="exportCaseTable">
        <thead>
          <tr>
            <th
              onClick={() => handleSortChange(exportsSortByOptions.caseNumber)}
            >
              Case Number
              <ExportsSortOrderIcon
                headerName={exportsSortByOptions.caseNumber}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th>ID</th>
            <th
              onClick={() =>
                handleSortChange(exportsSortByOptions.createdByName)
              }
            >
              Created By{" "}
              <ExportsSortOrderIcon
                headerName={exportsSortByOptions.createdByName}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th
              onClick={() => handleSortChange(exportsSortByOptions.createdOn)}
            >
              Created On
              <ExportsSortOrderIcon
                headerName={exportsSortByOptions.createdOn}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </th>
            <th>Events</th>
            <th>EXPORT</th>
            <th>View Case Details</th>
          </tr>
        </thead>

        <tbody>
          {sortedCases.map((casesData, index) => {
            return (
              <ExportCasesTableRow
                casesData={casesData}
                key={index}
                openPublishModal={openExportModal}
              />
            );
          })}
        </tbody>
      </table>
      {showModal &&
        typeof selectedExportId === "string" &&
        selectedExportId.length > 0 && (
          <ExportsInputForm
            setShowModal={setShowModal}
            selectedExportId={selectedExportId}
            selectedCaseNumber={selectedCaseNumber}
          />
        )}
    </div>
  );
};
