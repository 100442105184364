const AppFooter = () => {
  return (
    <footer>
      <div className="footer-left">
        <p>
          <img
            alt="Motorola Logo"
            src="/Content/images/logos/Motorola_WW_Horizontal_RGB_1118.png"
            width={143}
            style={{ height: "21px", paddingRright: "10px", width: "187px" }}
          />
          Copyright ©2023 Motorola Solutions, Inc. All rights reserved.
        </p>
      </div>
      <div className="footer-center">ʕノ•ᴥ•ʔノ ︵ ┻━┻</div>
      <div className="footer-right">
        {/* eslint-disable-next-line */}
        <p>
          Version: <span style={{ marginRight: "20px" }}>1.0.0</span>Build:{" "}
          <span style={{ marginRight: "20px" }}>0001</span>
          <i className="icon-info-circle icon-small" />
        </p>
      </div>
    </footer>
  );
};

export default AppFooter;
