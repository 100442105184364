import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestAssignIncidentNumber } from "../Store/mutations";
import classnames from "classnames";
import "./Incidents.css";
import moment from "moment";
import useGetEventUrls from "./useGetEventUrls";

export const IncidentEventRow = ({
  incidentEvent,
  incidentNumber,
  isLoading,
  onAssignIncidentNumber,
  delimiter,
  disableMultiTag,
}) => {
  const { playbackUrl, detailUrl } = useGetEventUrls(incidentEvent);

  const IncidentEventContainsIncidentNumber = (
    incidentEvent,
    incidentNumber
  ) => {
    if (incidentEvent.incident == null) {
      return false;
    }
    return incidentEvent.incident.toString().includes(incidentNumber);
  };

  const isMultiTagged =
    incidentEvent.incident !== null &&
    incidentEvent.incident.includes(delimiter) &&
    !disableMultiTag;

  var multiTagIncidents;
  if (isMultiTagged) {
    multiTagIncidents = incidentEvent.incident.split(delimiter);
  }

  const unassignable = () => {
    return IncidentEventContainsIncidentNumber(incidentEvent, incidentNumber);
  };

  return (
    <IncidentEventRowPure
      incidentEvent={incidentEvent}
      isLoading={isLoading}
      onAssignIncidentNumber={onAssignIncidentNumber}
      playbackUrl={playbackUrl}
      detailUrl={detailUrl}
      isMultiTagged={isMultiTagged}
      multiTagIncidents={multiTagIncidents}
      unassignable={unassignable}
    />
  );
};

IncidentEventRow.propTypes = {
  incidentEvent: PropTypes.shape({
    id: PropTypes.string,
    officerFirstName: PropTypes.string,
    officerLastName: PropTypes.string,
    device: PropTypes.string,
    recordStart: PropTypes.string.isRequired,
    recordStop: PropTypes.string.isRequired,
    eventCategory: PropTypes.string.isRequired,
    incident: PropTypes.string,
  }).isRequired,
  onAssignIncidentNumber: PropTypes.func.isRequired,
  delimiter: PropTypes.string.isRequired,
  disableMultiTag: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  incidentEvent: state.incidentEvents.find(
    (incidentEvent) => incidentEvent.id === ownProps.id
  ),
  isLoading: state.loadingMessage !== false,
  incidentNumber: state.incidentNumber,
  delimiter: state.taggingOptions.secondaryTagMultiTagDelimiter,
  disableMultiTag: state.taggingOptions.disableMultiTag,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAssignIncidentNumber: () =>
    dispatch(requestAssignIncidentNumber(ownProps.id)),
});

const ConnectedIncidentEventRow = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentEventRow);

export default ConnectedIncidentEventRow;

export const IncidentEventRowPure = ({
  incidentEvent,
  isLoading,
  onAssignIncidentNumber,
  playbackUrl,
  detailUrl,
  isMultiTagged,
  multiTagIncidents,
  unassignable,
}) => {
  return (
    <tr>
      <td>{incidentEvent.isLinkedEvent && <i className="icon icon-link" />}</td>
      <td>{incidentEvent.reid}</td>
      <td>
        <div className="dropdown">
          <i className="dropdown-icon icon icon-ellipsis-v onHover"></i>
          <div className="dropdown-content">
            <a
              href={detailUrl}
              target="_blank"
              rel="noreferrer"
              className={classnames("dropbtn top", {
                playback: incidentEvent.id,
              })}
              type="button"
              tooltip="Use this button to view details for the specific event"
              disabled={isLoading}
            >
              <span className="icon icon-info-circle dropbtn"></span>
              Event Details
            </a>
            <a
              href={playbackUrl}
              target="_blank"
              rel="noreferrer"
              className={classnames("dropbtn bottom", {
                playback: incidentEvent.id,
              })}
              type="button"
              tooltip="Use this button to play the video for the specific event"
              disabled={isLoading}
            >
              <span className="icon icon-play-circle dropbtn"></span>
              Event Playback
            </a>
          </div>
        </div>
      </td>
      <td className="eventcriteria">
        {isMultiTagged
          ? multiTagIncidents.map((string, index) => {
              return <p key={index}>{string}</p>;
            })
          : incidentEvent.incident}
      </td>

      <td className="eventcriteria">
        {moment.utc(incidentEvent.recordStart).local().format("YYYY-MM-DD ")}
        <span className="bold">
          {moment.utc(incidentEvent.recordStart).local().format("HH:mm")}
        </span>
      </td>
      <td className="eventcriteria">
        {moment.utc(incidentEvent.recordStop).local().format("YYYY-MM-DD ")}
        <span className="bold">
          {moment.utc(incidentEvent.recordStop).local().format("HH:mm")}
        </span>
      </td>
      <td className="eventcriteria">
        {incidentEvent.officerFirstName} {incidentEvent.officerLastName}
      </td>
      <td>{incidentEvent.device}</td>
      <td>{incidentEvent.eventCategory}</td>
      <td>
        <button
          className={classnames("assignment-btn", {
            unassign: unassignable(),
          })}
          type="button"
          tooltip="Use this button to link this event to the selected incident"
          onClick={onAssignIncidentNumber}
          disabled={isLoading}
        >
          {unassignable() ? "Unassign" : "Assign"}
        </button>
      </td>
    </tr>
  );
};
