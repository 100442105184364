import ErrorMessageBox from "./ErrorMessagebox";
import NoTokenErrorMessageBox from "./NoTokenErrorMessagebox";

const ErrorBody = () => {
  var token = localStorage.getItem("token");
  return (
    <div id="content-outer-wrapper" className="flex flex-expand">
      <div className="flex" style={{ width: "100%" }}>
        <div id="content-wrapper">
          <section className="section-main" style={{ maxWidth: "500px" }}>
            <section className="section-scroll-fade-top"></section>
            <div className="section-scroll" style={{ background: "#eeeff2" }}>
              {token === "No Token" ? (
                <NoTokenErrorMessageBox />
              ) : (
                <ErrorMessageBox />
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ErrorBody;
