import { connect } from "react-redux";
import _ from "lodash";
import {
  updateIncidentNumberCriteria,
  updateIncidentDateCriteria,
  requestIncidentSearch,
} from "../Store/mutations";

const IncidentCriteriaInput = ({
  taggingOptions,
  updateIncidentNumberCriteria,
  updateIncidentDateCriteria,
  incidentSearchCriteria,
  requestIncidentSearch,
}) => {
  let incidentNumberIsEmpty = !incidentSearchCriteria.incidentNumber.length > 0;
  let incidentDateIsEmpty = !incidentSearchCriteria.incidentDate.length > 0;

  const handleIncidentNumberChange = (number) => {
    updateIncidentNumberCriteria(number);
  };
  const handleIncidentDateChange = (number) => {
    updateIncidentDateCriteria(number);
  };

  const handleKeyPress = (key) => {
    if (key === "Enter") {
      requestIncidentSearch();
    }
  };

  return (
    <IncidentCriteriaInputPure
      taggingOptions={taggingOptions}
      handleIncidentNumberChange={handleIncidentNumberChange}
      handleIncidentDateChange={handleIncidentDateChange}
      incidentSearchCriteria={incidentSearchCriteria}
      incidentNumberIsEmpty={incidentNumberIsEmpty}
      incidentDateIsEmpty={incidentDateIsEmpty}
      handleKeyPress={handleKeyPress}
    />
  );
};

const mapStateToProps = (state) => ({
  taggingOptions: JSON.parse(localStorage.getItem("AgencyConfig"))
    .taggingOptions,
  incidentSearchCriteria: state.incidentCriteria,
});

const mapDispatchToProps = (dispatch) => ({
  updateIncidentNumberCriteria: (number) =>
    dispatch(updateIncidentNumberCriteria(number)),
  updateIncidentDateCriteria: (date) =>
    dispatch(updateIncidentDateCriteria(date)),
  requestIncidentSearch: () => dispatch(requestIncidentSearch()),
});

const connectedIncidentCriteriaInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentCriteriaInput);

export default connectedIncidentCriteriaInput;

export const IncidentCriteriaInputPure = ({
  taggingOptions,
  handleIncidentNumberChange,
  handleIncidentDateChange,
  incidentSearchCriteria,
  incidentNumberIsEmpty,
  incidentDateIsEmpty,
  handleKeyPress,
}) => (
  <>
    <div className="incidentNumberInput">
      <div className="block"></div>
      <div className={"flex flex-middle"}>
        <label className="align-left">
          {taggingOptions.incidentNumberTagName ||
            _.startCase("incidentNumber")}
        </label>

        <span
          id={`qtipincidentNumber`}
          className="infocon"
          title={`Use ${taggingOptions.incidentNumberTagName} to narrow down the results`}
        ></span>

        {
          // eslint-disable-next-line
          <a
            className="clear-search-param flex-right"
            onClick={() => handleIncidentNumberChange("")}
          >
            <span
              className={
                "icon-close-circle " + (incidentNumberIsEmpty ? "disabled" : "")
              }
            />
          </a>
        }
      </div>
      <input
        aria-label="incidentNumberInput"
        type="text"
        id="incidentNumber"
        placeholder={taggingOptions.incidentNumberHint}
        value={incidentSearchCriteria.incidentNumber}
        onChange={(e) => handleIncidentNumberChange(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e.key)}
      />
    </div>

    <div className="incidentDateInput">
      <div className="block"></div>
      <div className={"flex flex-middle"}>
        <label className="align-left">
          {`${taggingOptions.incidentNumberTagName} Date`}
        </label>

        <span
          id={`qtipincidentDate`}
          className="infocon"
          title={`Use ${taggingOptions.incidentNumberTagName} Date to narrow down the results`}
        ></span>

        {
          // eslint-disable-next-line
          <a
            className="clear-search-param flex-right"
            onClick={() => handleIncidentDateChange("")}
          >
            <span
              className={
                "icon-close-circle " + (incidentDateIsEmpty ? "disabled" : "")
              }
            />
          </a>
        }
      </div>
      <input
        aria-label="incidentDateInput"
        data-testid="incidentDateInput"
        type="Date"
        id="incidentDate"
        value={incidentSearchCriteria.incidentDate}
        onChange={(e) => handleIncidentDateChange(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e.key)}
      />
    </div>
  </>
);
