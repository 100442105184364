import "./App.css";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import LandingPage from "./LandingPage/LandingPage";
import React from "react";
import useStartUp from "./useStartUp";

function App() {
  useStartUp();

  return (
    <div id="applicationHost" style={{ display: "block" }}>
      <section className="main-section">
        <AppHeader />
        <LandingPage />
        <AppFooter />
      </section>
    </div>
  );
}

export default App;
