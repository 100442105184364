import { connect } from "react-redux";

export const CaseLoadingMessage = ({ display, loadingMessage }) => {
  return (
    <div className="loadingMessage">
      <h1>
        {loadingMessage ? (
          <span className="secondary">
            <i className="icon-spinner-alt icon-spin" />
            {loadingMessage} . . .
          </span>
        ) : (
          <span>
            <i className="icon-search" />
            {display}
          </span>
        )}
      </h1>
    </div>
  );
};

export const displayLoadingText = (state) => {
  const sBuild = (type, count) => `${count} ${type}${count === 1 ? "" : "s"} `;
  let disp = sBuild("Case", state.cases.length);
  return disp.concat(" Displayed");
};

const mapStateToProps = (state) => ({
  display: displayLoadingText(state),
  loadingMessage: state.loadingMessage,
});

const ConnectedCaseLoadingMessage =
  connect(mapStateToProps)(CaseLoadingMessage);

export default ConnectedCaseLoadingMessage;
