import { useDispatch } from "react-redux";

import {
  requestIncidentSearch,
  updatePageSize,
  updatePageNumber,
} from "../Store/mutations";

const useIncidentPageControls = () => {
  const dispatch = useDispatch();

  const handleIncidentPageChange = (page) => {
    if (page.toString().length > 0 && page <= 0) {
      page = 1;
    }

    if (page.toString().length > 0) {
      page = Math.round(page);
    }

    dispatch(updatePageNumber("incident", page));
    dispatch(requestIncidentSearch());
  };

  const handleIncidentPageSizeChange = (size) => {
    dispatch(updatePageSize("incident", size));
    dispatch(updatePageNumber("incident", 1));
    dispatch(requestIncidentSearch());
  };

  return {
    handleIncidentPageChange,
    handleIncidentPageSizeChange,
  };
};

export default useIncidentPageControls;
