import ExportRowExportButton from "../ExportRowExportButton";
const ExportEventsTableRow = ({ incidentEventData, openPublishModal }) => {
  const {
    id,
    officerFirstName,
    officerLastName,
    eventCategory,
    isLinkedEvent,
    recordStart,
    recordStop,
  } = incidentEventData;

  const handleClick = () => {
    openPublishModal(id);
  };

  return (
    <tr>
      <td>{isLinkedEvent && <i className="icon icon-link" />}</td>
      <td>{id}</td>
      <td>{officerFirstName}</td>
      <td>{officerLastName}</td>
      <td>{eventCategory}</td>
      <td>{recordStart}</td>
      <td>{recordStop}</td>
      <td>
        <ExportRowExportButton handleClick={handleClick} />
      </td>
    </tr>
  );
};

export default ExportEventsTableRow;
