import React from "react";
import SearchForm from "../Common/SearchForm";
import InputText from "../Common/InputText";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { REQUEST_CASE_SEARCH, requestCaseReset } from "../Store/mutations";

export const CaseSearchForm = ({ onClear }) => {
  return (
    <SearchForm id="caseFilter" title={`Case Filter`}>
      <div className="flex flex-right">
        {/* eslint-disable-next-line */}
        <a className="flex-right" onClick={onClear}>
          Clear
        </a>
      </div>
      <InputText
        action={REQUEST_CASE_SEARCH}
        collection="caseCriteria"
        id="caseNumber"
        label="Case Number"
        tooltip={`Use Case Number to narrow down the results`}
        placeholder={`Enter Case Number`}
      />
    </SearchForm>
  );
};

CaseSearchForm.propTypes = {
  onClear: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onClear: () => dispatch(requestCaseReset()),
});

const ConnectedCaseSearchForm = connect(
  null,
  mapDispatchToProps
)(CaseSearchForm);
export default ConnectedCaseSearchForm;
