const HomeBody = () => {
  return (
    <div id="content-outer-wrapper" className="flex flex-expand">
      <div className="flex" style={{ width: "100%" }}>
        <div id="content-wrapper" className="full-height-container">
          <section className="section-main no-pad-bottom">
            <section className="section-scroll-fade-top"></section>
            <div className="section-scroll" style={{ background: "#eeeff2" }}>
              <section className="form-section">
                <h1>Welcome to Evidence Library Integration Manager 2.0</h1>
              </section>
            </div>

            <section className="section-scroll-fade-bottom"></section>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HomeBody;
