const storeURLQuery = () => {
  var query = new URLSearchParams(window.location.search);
  var token = query.get("token");
  if (token) {
    localStorage.setItem("token", token);
  }
  var incidentNumber = query.get("incidentNumber");
  if (incidentNumber) {
    localStorage.setItem("incidentNumber", incidentNumber);
  }
};
export default storeURLQuery;
