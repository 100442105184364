import { connect } from "react-redux";
import { useState } from "react";
import {
  updateOfficerForIncidentEventForExportSearchCriteria,
  updateStartDateForIncidentEventForExportSearchCriteria,
  updateEndDateForIncidentEventForExportSearchCriteria,
  RequestIncidentEventForExportSearch,
} from "../../../Store/mutations";
import moment from "moment";
import "../../Exports.css";

export const ExportEventInput = ({
  officers,
  officerId,
  searchDateBegin,
  searchDateEnd,
  updateOfficer,
  updateSearchDateBegin,
  updateSearchDateEnd,
  searchIncidentEventsForExport,
}) => {
  const dateTimeFormat = "YYYY-MM-DDTHH:mm";
  officers.sort(function (left, right) {
    var lastName = left.lastName.localeCompare(right.lastName);
    var firstName = left.firstName.localeCompare(right.lastName);
    return lastName || firstName;
  });

  const hasOfficerId = () => {
    return !isNaN(officerId) && officerId.length > 0;
  };

  const hasValidSearchDate = () => {
    return !isNaN(new Date(searchDateBegin)) && !isNaN(new Date(searchDateEnd));
  };

  const [officerFilter, setOfficerFilter] = useState("");
  const [showDatePicker, setShowDatePicker] = useState("none");

  const handleDateOptionSelection = (selection) => {
    setShowDatePicker("none");
    switch (selection) {
      case "all":
        updateSearchDateBegin(
          moment(new Date(1995, 0, 1)).format(dateTimeFormat)
        );
        updateSearchDateEnd(
          moment().startOf("day").add("1", "days").format(dateTimeFormat)
        );
        break;
      case "single":
        setShowDatePicker("single");
        break;
      case "yesterday":
        updateSearchDateBegin(
          moment().subtract(1, "days").startOf("day").format(dateTimeFormat)
        );
        updateSearchDateEnd(moment().startOf("day").format(dateTimeFormat));
        break;
      case "today":
        updateSearchDateBegin(moment().startOf("day").format(dateTimeFormat));
        updateSearchDateEnd(
          moment().add("1", "days").startOf("day").format(dateTimeFormat)
        );
        break;
      case "range":
        setShowDatePicker("range");
        break;
      case "lastseven":
        updateSearchDateBegin(
          moment().subtract("6", "days").startOf("day").format(dateTimeFormat)
        );
        updateSearchDateEnd(
          moment().add("1", "days").startOf("day").format(dateTimeFormat)
        );
        break;
      case "lastfourteen":
        updateSearchDateBegin(
          moment().subtract("13", "days").startOf("day").format(dateTimeFormat)
        );
        updateSearchDateEnd(
          moment().add("1", "days").startOf("day").format(dateTimeFormat)
        );
        break;
      case "lastthirty":
        updateSearchDateBegin(
          moment().subtract("29", "days").startOf("day").format(dateTimeFormat)
        );
        updateSearchDateEnd(
          moment().add("1", "days").startOf("day").format(dateTimeFormat)
        );
        break;
      case "lastsixty":
        updateSearchDateBegin(
          moment().subtract("59", "days").startOf("day").format(dateTimeFormat)
        );
        updateSearchDateEnd(
          moment().add("1", "days").startOf("day").format(dateTimeFormat)
        );
        break;
      case "lastninety":
        updateSearchDateBegin(
          moment().subtract("89", "days").startOf("day").format(dateTimeFormat)
        );
        updateSearchDateEnd(
          moment().add("1", "days").startOf("day").format(dateTimeFormat)
        );
        break;
      case "pastyear":
        updateSearchDateBegin(
          moment().subtract("364", "days").startOf("day").format(dateTimeFormat)
        );
        updateSearchDateEnd(
          moment().add("1", "days").startOf("day").format(dateTimeFormat)
        );
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="exportEventOfficerLabel">
        <label>Officer</label>
        <span
          id={`qtipEvent`}
          className="infocon"
          title={"Enter an officer Filter to narrow officer list"}
        />
      </div>
      <input
        placeholder="officer filter"
        onChange={(e) => setOfficerFilter(e.target.value)}
      />

      <select
        collection="exportCriteria"
        defaultValue={""}
        id="officer"
        label="Officer"
        tooltip={`Use Officer to narrow down the results`}
        placeholder={`select officer`}
        onChange={(e) => updateOfficer(e.target.value)}
      >
        <option value={""} disabled>
          Select Officer
        </option>
        {officers[0] === "loading"
          ? "loading..."
          : officers
              .filter(
                (officer) =>
                  officer.lastName
                    .concat(", ", officer.firstName)
                    .toLowerCase()
                    .includes(officerFilter) || officerFilter === ""
              )

              .map((officer, key) => (
                <option value={officer.id} id={key}>
                  {officer.lastName}, {officer.firstName}
                </option>
              ))}
      </select>
      <label>Date</label>
      <select
        defaultValue={"default"}
        onChange={(e) => handleDateOptionSelection(e.target.value)}
      >
        <option disabled value={"default"}>
          Choose Date Options
        </option>
        <option value="all">All Dates</option>
        <option value="single">Single Date</option>
        <option value="yesterday">Yesterday</option>
        <option value="today">Today</option>
        <option value="range">Date Range</option>
        <option value="lastseven">Last 7 days</option>
        <option value="lastfourteen">Last 14 days</option>
        <option value="lastthirty">Last 30 days</option>
        <option value="lastsixty">Last 60 days</option>
        <option value="lastninety">Last 90 days</option>
        <option value="pastyear">Past Year</option>
      </select>

      {showDatePicker === "single" && (
        <>
          <label>Select Date</label>
          <input
            collection="exportCriteria"
            type="date"
            id="exportDate"
            label="Export Date"
            tooltip={`Use Date to narrow down the results`}
            placeholder={`Enter Date`}
            onChange={(e) => {
              updateSearchDateBegin(
                moment(e.target.value).startOf("day").format(dateTimeFormat)
              );
              updateSearchDateEnd(
                moment(e.target.value)
                  .add("1", "days")
                  .startOf("day")
                  .format(dateTimeFormat)
              );
            }}
          />
        </>
      )}

      {showDatePicker === "range" && (
        <>
          <label>Select Start Date</label>
          <input
            collection="exportCriteria"
            type="date"
            id="exportDate"
            label="Export Date"
            tooltip={`Use Date to narrow down the results`}
            placeholder={`Enter Date`}
            onChange={(e) => {
              updateSearchDateBegin(
                moment(e.target.value).startOf("day").format(dateTimeFormat)
              );
            }}
          />
          <label>Select End Date</label>
          <input
            collection="exportCriteria"
            type="date"
            id="exportDate"
            label="Export Date"
            tooltip={`Use Date to narrow down the results`}
            placeholder={`Enter Date`}
            onChange={(e) => {
              updateSearchDateEnd(
                moment(e.target.value)
                  .add("1", "days")
                  .startOf("day")
                  .format(dateTimeFormat)
              );
            }}
          />
        </>
      )}

      <button
        onClick={(e) => {
          e.preventDefault();
          searchIncidentEventsForExport();
        }}
        className={
          hasValidSearchDate() && hasOfficerId()
            ? "searchButton"
            : "searchButton disabled"
        }
        disabled={!hasValidSearchDate() || !hasOfficerId()}
      >
        SEARCH
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  officers: state.officers,
  officerId: state.incidentEventForExportSearchCriteria.officerIds,
  searchDateBegin: state.incidentEventForExportSearchCriteria.searchDateBegin,
  searchDateEnd: state.incidentEventForExportSearchCriteria.searchDateEnd,
});

const mapDispatchToProps = (dispatch) => ({
  updateOfficer: (selection) =>
    dispatch(updateOfficerForIncidentEventForExportSearchCriteria(selection)),
  updateSearchDateBegin: (date) =>
    dispatch(updateStartDateForIncidentEventForExportSearchCriteria(date)),
  updateSearchDateEnd: (date) =>
    dispatch(updateEndDateForIncidentEventForExportSearchCriteria(date)),
  searchIncidentEventsForExport: () =>
    dispatch(RequestIncidentEventForExportSearch()),
});

const connectedExportEventInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportEventInput);

export default connectedExportEventInput;
