import { useDispatch } from "react-redux";

import {
  updatePageFilter,
  updatePageSize,
  updatePageNumber,
} from "../Store/mutations";

const useOfficerPageControls = () => {
  const dispatch = useDispatch();

  const handleOfficerPageChange = (page) => {
    if (page.length > 0 && page <= 0) {
      page = 1;
    }

    if (page.length > 0) {
      page = Math.ceil(page);
    }
    dispatch(updatePageNumber("officer", page));
  };

  const handleOfficerPageSizeChange = (size) => {
    dispatch(updatePageSize("officer", size));
  };

  const handleOfficerFilterChange = (newFilter) => {
    dispatch(updatePageFilter("officer", newFilter));
  };

  return {
    handleOfficerPageChange,
    handleOfficerPageSizeChange,
    handleOfficerFilterChange,
  };
};

export default useOfficerPageControls;
