import { useDispatch } from "react-redux";

import {
  requestCaseSearch,
  updatePageNumber,
  updatePageSize,
  updatePageFilter,
} from "../Store/mutations";

const useCasePageControls = () => {
  const dispatch = useDispatch();

  const handleCasePageChange = (pageName, page) => {
    if (page.length > 0 && page <= 0) {
      page = 1;
    }

    if (page.length > 0) {
      page = Math.ceil(page);
    }

    dispatch(updatePageNumber(pageName, page));
    if (pageName === "case") {
      dispatch(requestCaseSearch());
    }
  };

  const handleCasePageFilterChange = (pageName, filter) => {
    dispatch(updatePageFilter(pageName, filter));
  };

  const handleCasePageSizeChange = (pageName, size) => {
    dispatch(updatePageSize(pageName, size));
    if (pageName === "case") {
      dispatch(requestCaseSearch());
    }
  };

  return {
    handleCasePageChange,
    handleCasePageFilterChange,
    handleCasePageSizeChange,
  };
};

export default useCasePageControls;
