import useCasePageControls from "./useCasePageControls";

import { connect } from "react-redux";
import { useEffect } from "react";
import CaseLoadingMessage from "./CaseLoadingMessage";

const CaseSearchHeader = ({ itemCount, pageNumber, pageSize }) => {
  const { handleCasePageChange, handleCasePageSizeChange } =
    useCasePageControls();

  useEffect(() => {
    if (itemCount <= 0) {
      return;
    }
    if (pageNumber > itemCount / pageSize) {
      handleCasePageChange("case", Math.ceil(itemCount / pageSize));
    }
    if (pageNumber === 0) {
      handleCasePageChange("case", 1);
    }
  }, [pageSize, pageNumber, itemCount, handleCasePageChange]);

  //temporarily disabled pagination element - pending a valid method to get accurate count of search result
  // const getMaxPages = () => {
  //   if (isNaN(pageItemCount)) return 1;
  //   return Math.ceil(pageItemCount < pageSize ? 1 : pageItemCount / pageSize);
  // };

  return (
    <CaseSearchHeaderPure
      handleCasePageSizeChange={handleCasePageSizeChange}
      pageSize={pageSize}
    />
  );
};

const mapStateToProps = (state) => ({
  pageNumber: state.pageControls.case.pageNumber,
  pageSize: state.pageControls.case.pageSize,
  itemCount: state.cases.length,
});

const ConnectedCaseSearchHeader = connect(mapStateToProps)(CaseSearchHeader);
export default ConnectedCaseSearchHeader;

export const CaseSearchHeaderPure = ({
  handleCasePageSizeChange,
  pageSize,
}) => {
  return (
    <>
      <div id="content-header" className="casesHeader">
        <div className="caseHeaderLeft">
          <CaseLoadingMessage />
        </div>
        <div className="flex-right paginationControl caseHeaderRight">
          <div className="flex flex-middle flex-auto flex-margin-small pagination">
            {
              //temporarily disabled pagination element - pending a valid method to get accurate count of search result
              /* <input
              type="number"
              value={pageNumber}
              min="1"
              step="1"
              max={getMaxPages()}
              onChange={(e) => {
                handleCasePageChange("case", e.target.value);
              }}
              className="paginationElement"
            />
            <span className="fancy paginationElement">of&nbsp;&nbsp;</span>
            <span className="paginationElement">{getMaxPages()}</span>
          </div>
          <div className="pageChangeArrows">
            <i
              disabled={pageNumber <= "1"}
              className={`icon icon-chevron-left tip-top  ${
                pageNumber <= "1" ? "disabled" : ""
              }`}
              onClick={() => handleCasePageChange("case", pageNumber - 1)}
            />
            <i
              disabled={pageNumber >= getMaxPages()}
              className={`icon icon-chevron-right tip-top ${
                pageNumber >= getMaxPages() ? "disabled" : ""
              }`}
              onClick={() => handleCasePageChange("case", pageNumber + 1)}
            />*/
            }
          </div>
          <select
            onChange={(e) => handleCasePageSizeChange("case", e.target.value)}
            defaultValue={pageSize}
          >
            <option value="50">50 Per Page</option>
            <option value="100">100 Per Page</option>
            <option value="250">250 Per Page</option>
          </select>
        </div>
      </div>
    </>
  );
};
