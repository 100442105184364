import useOfficerPageControls from "./useOfficerPageControls";
import { connect } from "react-redux";
import { useEffect } from "react";

const OfficerPagination = ({
  loadingMessage,
  numberOfOfficers,
  pageNumber,
  pageSize,
  filter,
}) => {
  const {
    handleOfficerFilterChange,
    handleOfficerPageChange,
    handleOfficerPageSizeChange,
  } = useOfficerPageControls();

  useEffect(() => {
    if (numberOfOfficers <= 0) {
      return;
    }
    if (pageNumber > numberOfOfficers / pageSize) {
      handleOfficerPageChange(Math.ceil(numberOfOfficers / pageSize));
    }
    if (pageNumber === 0) {
      handleOfficerPageChange(1);
    }
  }, [pageSize, pageNumber, filter, numberOfOfficers, handleOfficerPageChange]);

  return (
    <OfficerPaginationPure
      loadingMessage={loadingMessage}
      pageNumber={pageNumber}
      numberOfOfficers={numberOfOfficers}
      pageSize={pageSize}
      handleOfficerPageChange={handleOfficerPageChange}
      handleOfficerPageSizeChange={handleOfficerPageSizeChange}
      handleOfficerFilterChange={handleOfficerFilterChange}
    />
  );
};

const mapStateToProps = (state) => ({
  pageNumber: state.pageControls.officer.pageNumber,
  pageSize: state.pageControls.officer.pageSize,
  filter: state.pageControls.officer.pageFilter,
});

const ConnectedOfficerPagination = connect(mapStateToProps)(OfficerPagination);
export default ConnectedOfficerPagination;

export const OfficerPaginationPure = ({
  loadingMessage,
  pageNumber,
  numberOfOfficers,
  pageSize,
  handleOfficerPageChange,
  handleOfficerPageSizeChange,
  handleOfficerFilterChange,
}) => {
  return (
    <>
      {!loadingMessage && (
        <div className="flex-right paginationControl">
          <div className="flex flex-middle flex-auto flex-margin-small pagination">
            <input
              type="number"
              value={pageNumber}
              min="1"
              step="1"
              max={numberOfOfficers / pageSize}
              onChange={(e) => {
                handleOfficerPageChange(e.target.value);
              }}
              className="paginationElement"
            />
            <span className="fancy paginationElement">of&nbsp;&nbsp;</span>
            <span aria-label="maxpages" className="paginationElement">
              {Math.ceil(
                numberOfOfficers < pageSize ? 1 : numberOfOfficers / pageSize
              )}
            </span>
          </div>
          <div className="pageChangeArrows">
            <i
              disabled={pageNumber <= "1"}
              className={`icon icon-chevron-left tip-top  ${
                pageNumber <= "1" ? "disabled" : ""
              }`}
              onClick={() => handleOfficerPageChange(pageNumber - 1)}
            />
            <i
              disabled={pageNumber >= numberOfOfficers / pageSize}
              className={`icon icon-chevron-right tip-top ${
                pageNumber >= numberOfOfficers / pageSize ? "disabled" : ""
              }`}
              onClick={() => handleOfficerPageChange(pageNumber + 1)}
            />
          </div>
          <select onChange={(e) => handleOfficerPageSizeChange(e.target.value)}>
            <option value="50">50 Per Page</option>
            <option value="100">100 Per Page</option>
            <option value="250">250 Per Page</option>
          </select>
          <div className="flex flex-right flex-margin-small officer-filter">
            <input
              placeholder="Filter.."
              onChange={(e) => handleOfficerFilterChange(e.target.value)}
            ></input>
          </div>
        </div>
      )}
    </>
  );
};
