import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ExportForm from "./ExportForm";
import IncidentActions from "./incident.actions.json";

export class ActionMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedAction: null };
    this.rightTabRef = React.createRef();
  }
  onSelectAction(action) {
    this.setState({ selectedAction: action });
  }
  render() {
    var dropdown;
    var dropdownMenuItemQuantity = IncidentActions.length;
    if (dropdownMenuItemQuantity > 0) {
      switch (dropdownMenuItemQuantity) {
        case 1:
          var action = IncidentActions[0];
          dropdown = (
            <div className="block-right flex flex-col">
              <div className="flex-right dropdown-container drop-left">
                <div
                  className={classnames(
                    "dropdown-icon onHover",
                    action.IconClass
                  )}
                ></div>
                <div className="dropdown-drop">
                  <ul className="dropdown-results">
                    <li style={{ display: "inherit" }}>
                      {/* eslint-disable-next-line */}
                      <a onClick={() => this.onSelectAction(action)}>
                        {action.Label}
                      </a>
                      {this.state.selectedAction === action && (
                        <ExportForm
                          id={this.props.id}
                          onClose={() => this.onSelectAction()}
                          boundingBox={this.rightTabRef.current.getBoundingClientRect()}
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
          break;
        default: // More than 1
          dropdown = (
            <div className="block-right flex flex-col">
              <div className="flex-right dropdown-container drop-left">
                <div className="dropdown-icon icon-ellipsis-v onHover"></div>
                <div className="dropdown-drop">
                  <ul className="dropdown-results">
                    {/* eslint-disable-next-line */}
                    {IncidentActions.map((action, index) => (
                      <li key={index}>
                        {/* eslint-disable-next-line */}
                        <a className="bottom-hr">
                          <span className={action.IconClass}></span>
                          {action.Label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          );
          break;
      }
    }
    return (
      <div
        className="block-right flex flex-col"
        onClick={(event) => event.stopPropagation()}
        ref={this.rightTabRef}
      >
        {dropdown}
      </div>
    );
  }
}

ActionMenu.propTypes = {
  actions: PropTypes.any,
  id: PropTypes.string.isRequired,
};

export default ActionMenu;
