import React from "react";
import SearchForm from "../../Common/SearchForm";
import ExportEventInput from "./Events/ExportEventInput";
import InputText from "../../Common/InputText";

import { connect } from "react-redux";
import {
  updateExportDestination,
  updateExportType,
  requestCaseSearch,
  requestIncidentEventSearch,
  // requestOfficerList,
  updateCaseCriteria,
  REQUEST_CASE_SEARCH,
} from "../../Store/mutations";

const ExportSearchForm = ({
  // officers,
  configurationIds,
  types,
  selectedType,
  selectedDestination,
  updateSelectedDestination,
  updateSelectedType,
  requestCaseSearch,
  requestIncidentEventSearch,
  caseSearchNumber,
  // requestOfficerList,
}) => {
  // useEffect(() => {
  //   if (officers.length === 0) {
  //     requestOfficerList();
  //   }
  // }, [officers, requestOfficerList]);

  const sanitize = (caseNumber) => {
    if (caseNumber.length === 0) return caseNumber;
    caseNumber = caseNumber.replaceAll("+", "");
    caseNumber = caseNumber.replaceAll("\\", "");
    caseNumber = caseNumber.replaceAll("/", "");
    while (caseNumber[0] === ".") {
      caseNumber = caseNumber.substring(1);
    }
    return caseNumber.trim();
  };
  const handleTypeChange = (value) => {
    updateSelectedType(value);
    switch (value) {
      case "case":
        requestCaseSearch();
        break;
      case "event":
        requestIncidentEventSearch();
        break;
      default:
        break;
    }
  };
  return (
    <SearchForm id="exportControl" title={`Export Control`}>
      <label>Export To:</label>
      <select
        aria-label="ExportDestination"
        defaultValue={selectedDestination}
        onChange={({ target }) => updateSelectedDestination(target.value)}
      >
        <option disabled value="default">
          Choose destination
        </option>
        {configurationIds.map((value, index) => (
          <option value={value} key={index}>
            {value}
          </option>
        ))}
      </select>
      <label>Export Type:</label>
      <select
        aria-label="ExportType"
        defaultValue={selectedType}
        onChange={({ target }) => handleTypeChange(target.value)}
      >
        <option disabled value="default">
          Choose Export Type
        </option>
        {types.case && <option value="case">Case</option>}
        {types.event && <option value="event">Event</option>}
      </select>

      {selectedType === "case" && (
        <>
          <InputText
            aria-label="caseNumberFilter"
            action={REQUEST_CASE_SEARCH}
            collection="caseCriteria"
            id="caseNumber"
            label="Case Number"
            tooltip={`Use Case Number to narrow down the results`}
            placeholder={`Enter Case Number`}
            value={caseSearchNumber}
            sanitize={sanitize}
          />
        </>
      )}
      {selectedType === "event" && <ExportEventInput />}
    </SearchForm>
  );
};

const mapStateToProps = (state) => ({
  officers: state.officers,
  configurationIds: state.genericExportOptions.configurationIds,
  types: state.genericExportOptions.types,
  selectedDestination: state.genericExportOptions.selectedDestination,
  selectedType: state.genericExportOptions.selectedType,
  caseSearchNumber: state.caseCriteria.caseNumber,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedType: (value) => dispatch(updateExportType(value)),
  updateSelectedDestination: (value) =>
    dispatch(updateExportDestination(value)),
  requestCaseSearch: () => dispatch(requestCaseSearch()),
  requestIncidentEventSearch: () => dispatch(requestIncidentEventSearch()),
  // requestOfficerList: () => dispatch(requestOfficerList()),
  updateCaseCriteria: (value) => dispatch(updateCaseCriteria(value)),
});

const ConnectedExportSearchForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportSearchForm);
export default ConnectedExportSearchForm;
