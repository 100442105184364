import React from "react";
import SearchForm from "../Common/SearchForm";
import IncidentCriteriaInput from "./IncidentCriteriaInput";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  requestIncidentSearch,
  requestIncidentReset,
} from "../Store/mutations";

export const IncidentSearchForm = ({
  onClear,
  taggingOptions,
  requestIncidentSearch,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    requestIncidentSearch();
  };
  return (
    <IncidentSearchFormPure
      onClear={onClear}
      taggingOptions={taggingOptions}
      handleSubmit={handleSubmit}
    />
  );
};

IncidentSearchForm.propTypes = {
  onClear: PropTypes.func.isRequired,
  taggingOptions: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({
  taggingOptions: JSON.parse(localStorage.getItem("AgencyConfig"))
    .taggingOptions,
});

const mapDispatchToProps = (dispatch) => ({
  onClear: () => dispatch(requestIncidentReset()),
  requestIncidentSearch: () => dispatch(requestIncidentSearch()),
});

const ConnectedIncidentSearchForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentSearchForm);
export default ConnectedIncidentSearchForm;

export const IncidentSearchFormPure = ({
  onClear,
  taggingOptions,
  handleSubmit,
}) => (
  <SearchForm
    id="incidentFilter"
    title={`${taggingOptions.incidentNumberTagName} Filter`}
  >
    <div className="flex flex-right">
      {/* eslint-disable-next-line */}
      <a className="flex-right" onClick={onClear}>
        Clear All
      </a>
    </div>
    <IncidentCriteriaInput />
    <button style={{ marginTop: "10px" }} onClick={(e) => handleSubmit(e)}>
      Search
    </button>
  </SearchForm>
);
