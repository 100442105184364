const GetIntegrationHeader = () => {
  return (
    <div id="content-header-container">
      <div id="content-header" className="flex">
        <h1 className="flex-10">
          <i className="icon-circle" />
          <span>Get Integration Manager</span>
        </h1>
      </div>
    </div>
  );
};

export default GetIntegrationHeader;
